/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { EnvironmentResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import DataLoader from "~/components/DataLoader/index";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import SaveDialogLayout from "../../../components/DialogLayout/SaveDialogLayout";
import { Callout, CalloutType } from "../../../primitiveComponents/dataDisplay/Callout/Callout";
interface UpdateConnectionForTenantDialogProps {
    existingProjectLink?: {
        projectId: string;
        environmentIds: string[];
    };
    projectId: string;
    projectName: string;
    tenantId: string;
    selectedEnvironmentIds: string[];
    onUpdated(tenant: TenantResource): void;
}
interface UpdateConnectionForTenantDialogState extends DataBaseComponentState {
    environmentIds: string[];
    availableEnvironments: EnvironmentResource[];
}
interface InitialDataProps {
    availableEnvironments: EnvironmentResource[];
}
const InitialDataLoader = DataLoader<InitialDataProps>();
export function UpdateConnectionForTenantDialog(props: UpdateConnectionForTenantDialogProps) {
    return (<InitialDataLoader load={async () => {
            const availableEnvironments = await repository.Projects.getAvailableEnvironmentsForProject(props.projectId);
            return { availableEnvironments: availableEnvironments.Environments };
        }} operationName="UpdateTenantProjectConnection" renderWhenLoaded={(data) => <UpdateConnectionForTenant {...data} {...props}/>} renderAlternate={({ busy, errors }) => <SaveDialogLayout title={`Change Connection to ${props.projectName}`} busy={busy} errors={errors} onSaveClick={() => Promise.resolve(true)}/>}/>);
}
class UpdateConnectionForTenant extends DataBaseComponent<UpdateConnectionForTenantDialogProps & InitialDataProps, UpdateConnectionForTenantDialogState> {
    constructor(props: UpdateConnectionForTenantDialogProps & InitialDataProps) {
        super(props);
        this.state = {
            environmentIds: props.selectedEnvironmentIds,
            availableEnvironments: props.availableEnvironments,
        };
    }
    handleEnvironmentsSelected = async (environmentIds: string[]) => {
        this.setState({ environmentIds });
    };
    handleSelectAllEnvironments = async () => {
        const allEnvironmentIds = this.state.availableEnvironments ? this.state.availableEnvironments.map((x) => x.Id) : [];
        this.setState({ environmentIds: allEnvironmentIds });
    };
    renderEnvironmentMultiSelect() {
        return (<>
                <EnvironmentMultiSelect key="select" onChange={this.handleEnvironmentsSelected} value={this.state.environmentIds} environments={this.state.availableEnvironments || []} fallbackLabel="Missing from Lifecycle" fallbackDescription="This environment is no longer part of the lifecycle. Please remove it from this tenant." autoFocus/>
                {this.state.environmentIds && this.state.environmentIds.length === 0 ? (<Callout key="callout" title="No Environments Selected" type={CalloutType.Information}>
                        <p>A tenant needs to be linked to an environment of a project before deployments can take place.</p>
                        <p>
                            <a href="#" onClick={async (e) => {
                    e.preventDefault();
                    await this.handleSelectAllEnvironments();
                }}>
                                Select all available environments
                            </a>
                        </p>
                    </Callout>) : null}
                {this.tenantHasMissingEnvironments() && <Callout title="One or more environments are no longer part of the associated lifecycle. Please remove the missing environment(s) from this tenant." type={CalloutType.Warning}/>}
            </>);
    }
    tenantHasMissingEnvironments() {
        const { availableEnvironments, environmentIds } = this.state;
        const environmentIdIsMissing = (id: string) => availableEnvironments.findIndex((e) => e.Id === id) === -1;
        return environmentIds.findIndex((id) => environmentIdIsMissing(id)) !== -1;
    }
    save = async () => {
        return this.doBusyTask(async () => {
            const tenant = await repository.Tenants.get(this.props.tenantId);
            tenant.ProjectEnvironments[this.props.projectId] = this.state.environmentIds ? this.state.environmentIds : [];
            const savedTenant = await repository.Tenants.save(tenant);
            setTimeout(() => this.props.onUpdated(savedTenant), 0);
        });
    };
    render() {
        return (<SaveDialogLayout title={`Change Connection to ${this.props.projectName}`} busy={this.state.busy} errors={this.errors} onSaveClick={this.save} savePermission={{ permission: Permission.TenantEdit, tenant: this.props.tenantId }} saveButtonDisabled={this.tenantHasMissingEnvironments()} saveButtonLabel={"Update Connection"}>
                {this.renderEnvironmentMultiSelect()}
            </SaveDialogLayout>);
    }
    static displayName = "UpdateConnectionForTenant";
}
