import { RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import { toGitBranch } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import * as React from "react";
import styles from "~/areas/projects/components/Releases/Edit/style.module.less";
import { GitRefChip } from "~/areas/projects/components/Releases/GitRefChip/GitRefChip";
import { FilesList } from "~/areas/projects/components/Releases/GitResources/FilesList";
import type { GitReferenceEditInfo } from "~/areas/projects/components/Releases/gitResourceModel";
import { GitReferenceType } from "~/areas/projects/components/Releases/gitResourceModel";
import { GitResourceReleaseSelectionContextualHelp } from "~/components/ContextualHelp/ContextualHelpSnippets";
import { withTheme } from "~/components/Theme/index";
import { CardFill } from "~/components/form/Sections/ExpandableFormSection";
import { ExpandableFormSection, Summary } from "~/components/form/index";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
import type { DataTableRowProps } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRow";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
interface GitReferenceDataTableRowProps extends DataTableRowProps {
    accessibleName: string;
}
class GitResourceDataTableRow extends React.Component<GitReferenceDataTableRowProps> {
    render() {
        const { accessibleName, ...otherProps } = this.props;
        const accessibilityProps = {
            role: "radiogroup",
            "aria-label": accessibleName,
        };
        return (<DataTableRow {...otherProps} {...accessibilityProps}>
                {this.props.children}
            </DataTableRow>);
    }
    static displayName = "GitResourceDataTableRow";
}
type GitResourceSelectorComponentProps = {
    gitResources: GitReferenceEditInfo[];
    onUpdateGitRef: (gitRef: GitReferenceEditInfo) => void;
    forRelease: boolean;
};
export class GitResourcesSelectorComponent extends React.Component<GitResourceSelectorComponentProps> {
    private buildGitResourceRadioButton(gitRef: GitReferenceEditInfo, type: GitReferenceType, label: React.ReactNode, onUpdateGitRef: (gitRef: GitReferenceEditInfo) => void) {
        return (<RadioButtonGroup className={styles.radioButtonContainer} value={gitRef.GitReferenceType} onChange={(newValue) => {
                if (type === GitReferenceType.specific)
                    gitRef.SpecificGitResource = { GitRef: toGitBranch(gitRef.DefaultBranch) };
                gitRef.GitReferenceType = newValue;
                onUpdateGitRef(gitRef);
            }}>
                <RadioButton accessibleName={`git-${type}`} className={styles.myRadioButton} value={type} label={label}/>
            </RadioButtonGroup>);
    }
    render() {
        const showLastGitResource = this.props.gitResources && this.props.gitResources.some((gr) => gr.LastGitResource !== null);
        const unresolvableResources = this.props.gitResources && this.props.gitResources.filter((r) => !r.IsResolvable);
        return withTheme((theme) => (<ExpandableFormSection errorKey="gitResources" title="Git Resources" fillCardWidth={CardFill.FillAll} summary={this.getSummary()} help={`Select Git reference(s) for this ${this.props.forRelease ? "release" : "snapshot"}`} isExpandedByDefault={unresolvableResources && unresolvableResources.length > 0}>
                <div className={styles.gitReferenceTableContainer}>
                    {unresolvableResources.length > 0 && (<Callout type={CalloutType.Danger} title="Unresolvable variables">
                            One or more steps with Git resources contain variables which are not resolvable at release creation:
                            <ul className={styles.unorderedList}>
                                {unresolvableResources.map((r) => (<li key={r.ActionName}>{r.ActionName}</li>))}
                            </ul>
                        </Callout>)}
                    <DataTable className={styles.packageTable}>
                        <DataTableHeader>
                            <DataTableRow>
                                <DataTableHeaderColumn>Step Repository</DataTableHeaderColumn>
                                {showLastGitResource && <DataTableHeaderColumn>Last</DataTableHeaderColumn>}
                                <DataTableHeaderColumn>
                                    <span>Specific </span> <GitResourceReleaseSelectionContextualHelp />
                                </DataTableHeaderColumn>
                            </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                            {this.props.gitResources &&
                this.props.gitResources.map((r, ix) => (<GitResourceDataTableRow key={r.ActionName + ix} accessibleName={r.ActionName + ix}>
                                        <DataTableRowColumn key={r.ActionName + ix} className={cn(styles.repositoryColumn)}>
                                            <div className={styles.actionName}>{r.ActionName}</div>
                                            <div className={styles.repoUri}>{r.RepositoryUri}</div>
                                            {r.FilePathFilters?.length > 0 && <FilesList filePaths={r.FilePathFilters} actionName={r.ActionName}/>}
                                        </DataTableRowColumn>
                                        {showLastGitResource && (<DataTableRowColumn key={`rowForLast${ix}`} className={cn(styles.lastColumn)}>
                                                {r.LastGitResource &&
                            this.buildGitResourceRadioButton(r, GitReferenceType.last, <GitRefChip key={`${r.LastGitResource.GitRef} ${r.LastGitResource.GitCommit} ${ix}`} vcsRef={r.LastGitResource}></GitRefChip>, this.props.onUpdateGitRef)}
                                            </DataTableRowColumn>)}
                                        <DataTableRowColumn key={`rowForSpecific${ix}`} className={cn(styles.specificColumn)}>
                                            {this.buildGitResourceRadioButton(r, GitReferenceType.specific, <>
                                                    <em className="fa-solid fa-code-branch" style={{ color: theme.iconNeutral, fontSize: "0.850rem" }}/>
                                                    <span className={cn(styles.specificBranchOrTag)}>{r.DefaultBranch}</span>
                                                </>, this.props.onUpdateGitRef)}
                                        </DataTableRowColumn>
                                    </GitResourceDataTableRow>))}
                        </DataTableBody>
                    </DataTable>
                </div>
            </ExpandableFormSection>));
    }
    private getSummary() {
        if (!this.props.gitResources || this.props.gitResources.length === 0) {
            return Summary.placeholder("No Git references included");
        }
        if (this.props.gitResources.length === 1) {
            return Summary.summary("1 Git reference included");
        }
        return Summary.summary(`${this.props.gitResources.length} Git references included`);
    }
    static displayName = "GitResourcesSelectorComponent";
}
