import { ActionButton, ActionButtonType, RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import React from "react";
import CustomDialog from "~/components/Dialog/CustomDialog";
import { SmallDialogFrame, CustomDialogTitleBar, CustomDialogContent, CustomDialogActions } from "~/components/DialogLayout/Custom";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
enum MergeActions {
    MergeAndReview = "MergeAndReview",
    ClientChanges = "ClientChanges",
    Discard = "Discard"
}
interface ProcessesMergedDialogProps {
    open: boolean;
    onClose: () => void;
    onMerge: () => void;
    onDiscard: () => void;
    onAcceptClientChanges: () => void;
}
export const ProcessesMergedDialog: React.FC<ProcessesMergedDialogProps> = ({ onClose, onAcceptClientChanges, onDiscard, onMerge, open }) => {
    const [mergeAction, setMergeAction] = React.useState<MergeActions>(MergeActions.ClientChanges);
    const handleDone = React.useCallback(() => {
        if (mergeAction === MergeActions.Discard) {
            onDiscard();
        }
        else if (mergeAction === MergeActions.MergeAndReview) {
            onMerge();
        }
        else if (mergeAction === MergeActions.ClientChanges) {
            onAcceptClientChanges();
        }
    }, [mergeAction, onDiscard, onMerge, onAcceptClientChanges]);
    return (<CustomDialog open={open} close={onClose} render={() => (<SmallDialogFrame>
                    <CustomDialogTitleBar title={"Action Required"}/>
                    <CustomDialogContent>
                        <Callout title="Conflict Detected" type={CalloutType.Information}>
                            The process has been changed on the server since you have started editing this process. How would you like to proceed?
                        </Callout>
                        <RadioButtonGroup value={mergeAction} onChange={setMergeAction}>
                            <RadioButton value={MergeActions.ClientChanges} label="Overwrite server with my changes" isDefault={true}/>
                            <RadioButton value={MergeActions.MergeAndReview} label="Merge my changes with server and review"/>
                            <RadioButton value={MergeActions.Discard} label="Discard my changes"/>
                        </RadioButtonGroup>
                    </CustomDialogContent>
                    <CustomDialogActions actions={<ActionButton onClick={handleDone} label={"Continue"} type={ActionButtonType.Primary}/>}/>
                </SmallDialogFrame>)}/>);
};
ProcessesMergedDialog.displayName = "ProcessesMergedDialog"
export default ProcessesMergedDialog;
