import { Permission } from "@octopusdeploy/octopus-server-client";
import type { ResourceCollection, EventCategoryResource, EventResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import AuditListRow from "~/components/AuditListRow/AuditListRow";
import List from "~/components/PagingList";
import { PermissionCheck } from "~/components/PermissionCheck";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import Note from "~/primitiveComponents/form/Note/Note";
import type { DataBaseComponentState } from "../DataBaseComponent";
import { DataBaseComponent } from "../DataBaseComponent";
import Section from "../Section";
class EventListingList extends List<EventResource> {
}
interface EventListingProps {
    data: ResourceCollection<EventResource>;
    eventCategories: EventCategoryResource[];
    regarding?: string[];
    noDataMessage?: string;
    onPageSelected?(skip: number, p: number): Promise<ResourceCollection<EventResource>>;
}
export const NotPermittedToViewEventsCallout: React.FC<{}> = () => (<Callout type={CalloutType.Information} title={"Permission required"}>
        The {Permission.EventView} permission is required to view results here.
    </Callout>);
NotPermittedToViewEventsCallout.displayName = "NotPermittedToViewEventsCallout"
interface EventListingState extends DataBaseComponentState {
    data: ResourceCollection<EventResource> | null;
}
export default class EventListing extends DataBaseComponent<EventListingProps, EventListingState> {
    constructor(props: EventListingProps) {
        super(props);
        if (!this.props.regarding && !this.props.onPageSelected) {
            throw new Error("EventListing must have either `regarding` or `onPageSelected` props passed");
        }
        this.state = {
            data: null,
        };
    }
    render() {
        const initialData = this.state.data ? this.state.data : this.props.data;
        return (<div>
                <PermissionCheck permission={Permission.EventView} wildcard={true} alternate={<NotPermittedToViewEventsCallout />}>
                    {initialData && initialData.Items && initialData.Items.length > 0 ? (<EventListingList initialData={initialData} onRow={(item: EventResource) => {
                    const category = this.props.eventCategories.find((x) => x.Id === item.Category);
                    return <AuditListRow event={item} categoryName={category ? category.Name : item.Category}/>;
                }} showPagingInNumberedStyle={true} onPageSelected={async (skip: number, p: number) => {
                    const result = this.props.onPageSelected ? await this.props.onPageSelected(skip, p) : await repository.Events.list({ regarding: this.props.regarding, skip });
                    this.setState({ data: result });
                }} wrapRowsInListItem={false} // AuditListRow does custom styling on these rows.
            />) : (<Section>
                            <Note>{this.props.noDataMessage || "There's no data to show."}</Note>
                        </Section>)}
                </PermissionCheck>
            </div>);
    }
    static displayName = "EventListing";
}
