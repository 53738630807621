import { ActionButton, ActionButtonType, NavigationButton } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { UserResource } from "@octopusdeploy/octopus-server-client";
import { OctopusError, AuthenticationError } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { repository } from "~/clientInstance";
import BaseComponent from "~/components/BaseComponent";
import CenteredLayout from "~/components/CenteredLayout/CenteredLayout";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Text } from "~/components/form";
import Note from "~/primitiveComponents/form/Note/Note";
import InternalRedirect from "../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../routeLinks";
import styles from "./style.module.less";
interface RegisterState {
    redirectToReferrer: boolean;
    inviteCodeInUri?: boolean;
    inviteCode: string;
    userName: string;
    displayName: string;
    authenticationError?: AuthenticationError;
    emailAddress: string;
    password: string;
    confirmPassword: string;
    busyIndicator?: Promise<void>;
}
interface RegisterProps extends RouteComponentProps<{
    inviteCode: string;
}> {
    onSessionStarted: (user: UserResource) => Promise<void>;
}
export default class Register extends BaseComponent<RegisterProps, RegisterState> {
    constructor(props: RegisterProps) {
        super(props);
        this.state = {
            redirectToReferrer: false,
            userName: "",
            password: "",
            confirmPassword: "",
            inviteCode: "",
            displayName: "",
            emailAddress: "",
        };
    }
    async componentDidMount() {
        const inviteCode = this.props.match.params.inviteCode;
        this.setState({
            inviteCodeInUri: !!inviteCode,
            inviteCode,
        });
    }
    render() {
        if (this.state.redirectToReferrer) {
            return <InternalRedirect to={routeLinks.root}/>;
        }
        return (<CenteredLayout>
                <div className={styles.content}>
                    <div className={styles.logo}>
                        <div>
                            <em className="fontoctopus-octopus"/>
                        </div>
                        {!this.state.authenticationError && (<div>
                                <h4>Create an account to get started.</h4>
                            </div>)}
                    </div>
                    {this.state.authenticationError && this.showError(this.state.authenticationError)}
                    <form onSubmit={this.register} className={styles.form}>
                        {!this.state.inviteCodeInUri && <Text value={this.state.inviteCode} label="Invitation code" onChange={(inviteCode) => this.setState({ inviteCode })}/>}
                        <Text value={this.state.userName} label="Username" onChange={(userName) => this.setState({ userName })} autoFocus={true}/>
                        <Text value={this.state.displayName} label="Display name" onChange={(displayName) => this.setState({ displayName })}/>
                        <Text value={this.state.emailAddress} label="Email address" onChange={(emailAddress) => this.setState({ emailAddress })}/>
                        <Note>
                            This will be used to show your <ExternalLink href="Gravatar">Gravatar</ExternalLink>.
                        </Note>
                        <Text label="Password" value={this.state.password} type="password" onChange={(password) => this.setState({ password })} autoComplete="new-password"/>
                        <Text value={this.state.confirmPassword} type={"password"} onChange={(confirmPassword) => this.setState({ confirmPassword })} label="Confirm password" autoComplete="new-password"/>
                        <div className={styles.footerActions}>
                            <ActionButton type={ActionButtonType.Primary} label="Create Account" busyLabel="Creating..." onClick={this.register}/>
                            <div>
                                or <NavigationButton href={routeLinks.currentUser.signIn} label="sign in"/>
                            </div>
                        </div>
                    </form>
                </div>
            </CenteredLayout>);
    }
    private register = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const busyIndicator = this.registerUser(this.state.userName, this.state.password);
        this.setState({ busyIndicator });
        await busyIndicator;
    };
    private registerUser = async (userName: string, password: string) => {
        try {
            if (this.state.password !== this.state.confirmPassword) {
                throw new AuthenticationError("There was a problem with your request.", ["The passwords you entered do not match."]);
            }
            if (userName === null || userName.trim().length === 0) {
                throw new AuthenticationError("There was a problem with your request.", ["Please supply a username."]);
            }
            const result = await repository.Users.register({
                Username: userName,
                DisplayName: this.state.displayName,
                EmailAddress: this.state.emailAddress,
                Password: password,
                InvitationCode: this.state.inviteCode,
                IsActive: true,
            });
            const user = await repository.Users.signIn({ Username: userName, Password: password });
            await this.authenticationSucceeded(user);
        }
        catch (error) {
            this.setError(error);
        }
    };
    private async authenticationSucceeded(user: UserResource) {
        try {
            await this.props.onSessionStarted(user);
            this.setState({ redirectToReferrer: true });
        }
        catch (error) {
            this.setError(error);
        }
    }
    private setError(error: unknown) {
        const authenticationError = createAuthenticationError(error);
        logger.error(authenticationError, "Authentication error during registration");
        this.setState({ authenticationError });
    }
    private showError(authenticationError: AuthenticationError) {
        return (<div className={styles.authenticationError}>
                <ErrorPanel message={authenticationError.message} errors={authenticationError.Errors}/>
            </div>);
    }
    static displayName = "Register";
}
function createAuthenticationError(error: unknown): AuthenticationError {
    if (error instanceof AuthenticationError) {
        return error;
    }
    else if (error instanceof OctopusError) {
        return new AuthenticationError(error.ErrorMessage, error.Errors);
    }
    else {
        return new AuthenticationError("There was a problem with your request.");
    }
}
