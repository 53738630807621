import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import * as React from "react";
import { useDispatch } from "react-redux";
import { actions as dialogActions } from "~/components/Dialog/store/index";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import { formatUrl } from "~/components/Navigation/ExternalLink/ExternalLink";
import { useOctopusTheme } from "~/components/Theme";
interface LicenseLimitReachedDialogLayoutProps {
    limitType: "Spaces" | "Workers";
    onClose: () => void;
    onActionClick?: () => void;
}
const LicenseLimitReachedDialogLayout: React.FC<LicenseLimitReachedDialogLayoutProps> = (props) => {
    const theme = useOctopusTheme();
    const { onActionClick } = props;
    const notifyActionClick = React.useCallback(() => {
        if (onActionClick) {
            onActionClick();
        }
    }, [onActionClick]);
    // if you take the icon property:
    //      ` icon={<i className="fa fa-external-link" style={{ color: whiteConstant }} />}`
    // and apply it inside the action that launches this dialog
    // then it works, not sure why it works differently on this ActionButton
    const loadMoreInfo = (<ActionButton key={"unlock-more-" + props.limitType} label={"Unlock More " + props.limitType} icon={<i className="fa-solid fa-external-link" style={{ color: theme.whiteConstant }}/>} iconPosition="right" type={ActionButtonType.Primary} onClick={() => {
            window.open(formatUrl("Upgrade" + props.limitType), "_blank");
            notifyActionClick();
        }}/>);
    const cancel = <ActionButton key="Cancel" label="Cancel" onClick={() => close()}/>;
    return (<DialogLayout title={`You've hit your ${props.limitType} limit...`} actions={[cancel, loadMoreInfo]} busy={null} errors={null} closeDialog={() => close()} additionalActions={[]}>
            <div>Want to add more? You'll need to upgrade your licence. Click to see your upgrade options and get a quote.</div>
        </DialogLayout>);
    function close() {
        props.onClose();
        notifyActionClick();
    }
};
LicenseLimitReachedDialogLayout.displayName = "LicenseLimitReachedDialogLayout"
interface LimitReachedDialogProps {
    onActionClick?: () => void;
}
type SpaceLimitReachedDialogLayoutProps = LimitReachedDialogProps;
export function SpaceLimitReachedDialogLayout(props: SpaceLimitReachedDialogLayoutProps) {
    const closeDialogs = useCloseAllDialogs();
    return <LicenseLimitReachedDialogLayout limitType={"Spaces"} onActionClick={props.onActionClick} onClose={closeDialogs}/>;
}
type WorkerLimitReachedDialogLayoutProps = LimitReachedDialogProps;
export function WorkerLimitReachedDialogLayout(props: WorkerLimitReachedDialogLayoutProps) {
    const closeDialogs = useCloseAllDialogs();
    return <LicenseLimitReachedDialogLayout limitType={"Workers"} onActionClick={props.onActionClick} onClose={closeDialogs}/>;
}
function useCloseAllDialogs() {
    const dispatch = useDispatch();
    return React.useCallback(() => {
        dispatch(dialogActions.closeAll());
    }, [dispatch]);
}
