/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { LifecycleResource, PhaseResource, EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import MarkdownDescription from "~/components/MarkdownDescription";
import { Note } from "~/components/form";
import { mapProgressionToStatus } from "~/utils/MapProgressionToStatus/MapProgressionToStatus";
import LifecycleMapInternal from "./LifecycleMapInternal";
interface State {
    largeLifecycleToggle: boolean;
}
interface Props {
    lifecyclePreview: LifecycleResource;
    environmentsById: {
        [id: string]: EnvironmentResource;
    };
    className?: string;
    showDescription?: boolean;
    limitLargeLifecycles?: boolean;
}
export class LifecycleMap extends React.Component<Props, State> {
    private lifecycleThreshold = 10;
    private lifecycleStatus = mapProgressionToStatus();
    constructor(props: Props) {
        super(props);
        this.state = {
            largeLifecycleToggle: true,
        };
    }
    render() {
        const phases: PhaseResource[] = this.props.lifecyclePreview.Phases ? this.props.lifecyclePreview.Phases : [];
        const isLargeLifecyle = this.props.lifecyclePreview.Phases.length > this.lifecycleThreshold || this.props.lifecyclePreview.Phases.some((p: PhaseResource) => p.OptionalDeploymentTargets.length > this.lifecycleThreshold);
        return (<div>
                {this.props.showDescription && <MarkdownDescription markup={this.props.lifecyclePreview.Description!}/>}

                {this.props.limitLargeLifecycles && isLargeLifecyle && this.props.lifecyclePreview && this.props.lifecyclePreview.Phases && <Note>This lifecycle has many phases / environments</Note>}

                {this.props.limitLargeLifecycles && isLargeLifecyle && (<ActionButton onClick={() => this.setState({ largeLifecycleToggle: !this.state.largeLifecycleToggle })} label={this.state.largeLifecycleToggle ? "SHOW LIFECYCLE" : "HIDE LIFECYCLE"} type={ActionButtonType.Ternary}/>)}

                {(!isLargeLifecyle || !this.state.largeLifecycleToggle || !this.props.limitLargeLifecycles) && (<LifecycleMapInternal phases={phases} environmentsById={this.props.environmentsById} lifecycleStatus={this.lifecycleStatus} className={this.props.className}/>)}
            </div>);
    }
    static displayName = "LifecycleMap";
}
export default LifecycleMap;
