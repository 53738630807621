/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import type { PaletteType } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { useApplyGlobalThemeEffect } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import * as React from "react";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import { createMuiTheme } from "~/theme";
import { ThemeApplier } from "./ThemeApplier";
import type { OctopusTheme } from "./Themes";
import { DarkTheme, LightTheme } from "./Themes";
type ThemeProps = React.PropsWithChildren<{
    palette: PaletteType;
}>;
function Theme({ palette, children }: ThemeProps) {
    const theme = mapPaletteToTheme(palette);
    const muiTheme = React.useMemo(() => createMuiTheme(palette, theme), [palette, theme]);
    useApplyGlobalThemeEffect(palette);
    React.useEffect(() => {
        logger.info("Theme palette set to {palette}", { palette });
    }, [palette]);
    return (<>
            <ThemeApplier />
            <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
        </>);
}
function mapPaletteToTheme(themeName: string): OctopusTheme {
    return themeName === "light" ? LightTheme : DarkTheme;
}
function withTheme(render: (theme: OctopusTheme) => React.ReactElement | null | undefined): React.ReactElement {
    return <UsingTheme>{(theme) => render(theme)}</UsingTheme>;
}
type UsingThemeProps = {
    children: (theme: OctopusTheme) => React.ReactNode;
};
function UsingTheme({ children }: UsingThemeProps) {
    const theme = useOctopusTheme();
    return <>{children(theme)}</>;
}
function useOctopusTheme(): OctopusTheme {
    const palette = useThemePaletteType();
    return mapPaletteToTheme(palette);
}
export default Theme;
export { Theme, withTheme, useOctopusTheme };
export type { OctopusTheme };
