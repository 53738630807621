import { useOctopusSessionUser } from "@octopusdeploy/session";
import { useCallback } from "react";
import { useUIFeatureFlagToggledDispatch } from "~/hooks/analytics/useUIFeatureFlagDispatch";
import useLocalStorage from "~/hooks/useLocalStorage";
/**
 * Stores the value of a feature flag in local storage.
 * Each user will have a different value for the feature flag. In other words, the user id forms part of the local storage key.
 * @param featureFlagKey - The key for the feature flag, used as part of the local storage key
 * @param isInitiallyEnabled - Whether the feature flag should be initially enabled, before the user has configured the value. When there is nothing in local storage, this value will be used.
 */
export function useUIOnlyFeatureFlag(featureFlagKey: string, isInitiallyEnabled: boolean): [
    isEnabled: boolean,
    setIsEnabled: (isEnabled: boolean) => void
] {
    const currentUser = useOctopusSessionUser();
    const dispatchFeatureFlagToggled = useUIFeatureFlagToggledDispatch();
    const localStorageKey = `${currentUser.Id}/${featureFlagKey}`;
    const [localStorageValue, setLocalStorageValue] = useLocalStorage(localStorageKey, { value: isInitiallyEnabled });
    const setValue = useCallback((isEnabled: boolean) => {
        setLocalStorageValue({ value: isEnabled });
        dispatchFeatureFlagToggled(isEnabled, { featureFlagKey });
    }, [dispatchFeatureFlagToggled, featureFlagKey, setLocalStorageValue]);
    return [localStorageValue.value, setValue];
}
