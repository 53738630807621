/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { KubernetesSecretProperties } from "@octopusdeploy/legacy-action-properties";
import { GetNamedPackageReferences, PackageRequirement } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { EditResourceYaml } from "~/components/Actions/kubernetes/editResourceYaml";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import type { ScriptPackageProperties, ScriptPackageReference } from "~/components/Actions/script/ScriptPackageReferenceDialog";
import { ScriptPackageReferenceDialog } from "~/components/Actions/script/ScriptPackageReferenceDialog";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import DialogOpener from "~/components/Dialog/DialogOpener";
import StringKeyValueEditList from "~/components/EditList/KeyValueEditList";
import { default as ExpandableFormSection } from "~/components/form/Sections/ExpandableFormSection";
import Summary from "~/components/form/Sections/Summary";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { JsonUtils } from "~/utils/jsonUtils";
import type { ActionWithFeeds } from "../commonActionHelpers";
import { exportSecret, importSecret } from "./importYaml";
interface ScriptActionEditState {
    originalStepPackageRequirement: PackageRequirement;
    editPackageReference?: ScriptPackageReference;
    editPackageReferenceIndex?: number;
    resourceYaml: string;
}
export interface ActionEditorProps extends ActionEditProps<KubernetesSecretProperties, ScriptPackageProperties> {
    importLabels: boolean;
}
type KubernetesSecretComponentProps = ActionEditorProps;
type KubernetesSecretComponentInternalProps = KubernetesSecretComponentProps & ActionWithFeeds;
class KubernetesSecretComponentInternal extends BaseComponent<KubernetesSecretComponentInternalProps, ScriptActionEditState> {
    constructor(props: KubernetesSecretComponentInternalProps) {
        super(props);
        this.state = {
            originalStepPackageRequirement: PackageRequirement.LetOctopusDecide,
            editPackageReference: null!,
            editPackageReferenceIndex: null!,
            resourceYaml: exportSecret(this.props, this.props.importLabels),
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps: KubernetesSecretComponentInternalProps) {
        const yaml = exportSecret(nextProps, nextProps.importLabels);
        if (this.state.resourceYaml !== yaml) {
            this.setState({ resourceYaml: yaml });
        }
    }
    render() {
        const localNames = _.concat(this.props.localNames ? this.props.localNames : [], this.packageVariableNames());
        const editPackageReferenceDialog = (<DialogOpener open={!!this.state.editPackageReference} onClose={this.resetSelectedPackageReference}>
                <ScriptPackageReferenceDialog packageReference={this.state.editPackageReference!} runOn={this.props.runOn} feeds={this.props.feeds} localNames={localNames} projectId={this.props.projectId!} onChange={(packageReference) => this.savePackageReference(packageReference)} refreshFeeds={this.loadFeeds}/>
            </DialogOpener>);
        return (<div>
                {editPackageReferenceDialog}
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.SecretYaml" isExpandedByDefault={false} title="Edit YAML" summary={Summary.placeholder("Edit the resource YAML")} help={"Edit the resource YAML."}>
                    <EditResourceYaml yaml={this.state.resourceYaml} onSave={(value) => this.setState({ resourceYaml: value }, () => importSecret(this.props, value, this.props.importLabels))}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.SecretName" isExpandedByDefault={false} title="Secret Name" summary={this.secretSummary()} help={"The name of the secret resource"}>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.SecretName"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.SecretName"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.SecretName")} label="Secret name"/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.SecretValues" isExpandedByDefault={this.props.expandedByDefault} title="Secret Items" summary={this.secretValuesSummary()} help={"The secret resource values"}>
                    <StringKeyValueEditList items={this.props.properties["Octopus.Action.KubernetesContainers.SecretValues"]} name="Secret Item" separator="" onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.SecretValues"]: val })} valueLabel="Value" valueMultiline={true} valueRowsMax={5} keyLabel="Key" hideBindOnKey={false} projectId={this.props.projectId} gitRef={this.props.gitRef}/>
                </ExpandableFormSection>
            </div>);
    }
    private savePackageReference(packageReference: ScriptPackageReference) {
        const packageReferences = [...this.props.packages];
        if (this.state.editPackageReferenceIndex === null) {
            packageReferences.push(packageReference);
        }
        else {
            packageReferences[this.state.editPackageReferenceIndex!] = packageReference;
        }
        this.props.setPackages(packageReferences);
        this.resetSelectedPackageReference();
        return true;
    }
    private resetSelectedPackageReference = () => {
        this.setState({
            editPackageReference: null!,
            editPackageReferenceIndex: null!,
        });
    };
    private packageVariableNames = (): string[] => {
        return _.flatten(GetNamedPackageReferences(this.props.packages).map((pkg) => [
            `Octopus.Action.Package[${pkg.Name}].PackageId`,
            `Octopus.Action.Package[${pkg.Name}].FeedId`,
            `Octopus.Action.Package[${pkg.Name}].PackageVersion`,
            `Octopus.Action.Package[${pkg.Name}].Path`,
        ]));
    };
    private secretSummary() {
        if (this.props.properties["Octopus.Action.KubernetesContainers.SecretName"]) {
            return Summary.summary(<span>
                    Create a secret called <strong>{this.props.properties["Octopus.Action.KubernetesContainers.SecretName"]}</strong>
                </span>);
        }
        return Summary.placeholder(<span>Define the secret name</span>);
    }
    private secretValuesSummary() {
        const items = _.toPairs<object | string | number>(JsonUtils.tryParse(this.props.properties["Octopus.Action.KubernetesContainers.SecretValues"], {}));
        if (items.length !== 0) {
            return Summary.summary(<span>
                    <span>Create a secret with the values </span>
                    {_.chain(items)
                    .flatMap((item) => [
                    <strong>
                                {item[0]}: {item[1]?.toString().replace(/\n[\S\s]*/, " ...")}
                            </strong>,
                    <span>, </span>,
                ])
                    .slice(0, -1)
                    .value()}
                </span>);
        }
        return Summary.placeholder(<span>Define the secret values</span>);
    }
    private loadFeeds = async () => {
        return await this.props.refreshFeeds();
    };
    static displayName = "KubernetesSecretComponentInternal";
}
export function KubernetesSecretComponent(props: React.PropsWithChildren<KubernetesSecretComponentProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    return <KubernetesSecretComponentInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds}/>;
}
