import { useAnalyticSession } from "@octopusdeploy/portal-analytics";
import { useCallback } from "react";
const UIFeatureFlagEnabled = "UI Feature Enabled";
const UIFeatureFlagDisabled = "UI Feature Disabled";
export function useUIFeatureFlagToggledDispatch() {
    const session = useAnalyticSession();
    return useCallback((enabled: boolean, options: UIFeatureFlagOptions) => {
        session.track(enabled ? UIFeatureFlagEnabled : UIFeatureFlagDisabled, mapOptionsToFeatureFlagFields(options));
    }, [session]);
}
function mapOptionsToFeatureFlagFields(options: UIFeatureFlagOptions): UIFeatureFlagFields {
    return {
        "Feature Flag Key": options.featureFlagKey,
    };
}
interface UIFeatureFlagOptions {
    featureFlagKey: string;
}
interface UIFeatureFlagFields {
    "Feature Flag Key": string;
}
