/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { GuidedFailureMode, HasGitPersistenceSettings, Permission, ProcessType, TenantedDeploymentMode, getGitRefType, IsDefaultBranch, ProjectContextRepository, toGitBranchWhenUnknown } from "@octopusdeploy/octopus-server-client";
import type { ChannelResource, CreateDeploymentResource, DeploymentPromotionTarget, DeploymentPromotionTenant, DeploymentResource, DeploymentSettingsResource, DeploymentTemplateResource, EnvironmentResource, IExecutionResource, OctopusError, ProjectResource, ReleaseResource, TaskResource, TenantResource, DynamicEnvironmentResource, Form, FormElement, LatestReleaseResource, GetBffDeploymentPreview, } from "@octopusdeploy/octopus-server-client";
import type { Dictionary } from "lodash";
import * as _ from "lodash";
import type { Moment } from "moment";
import * as PLimit from "p-limit";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { ActionEvent, AnalyticErrorCallback, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useProjectScopedAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import ActionToggle from "~/areas/projects/components/Releases/Deployments/ActionToggle";
import EnvironmentAndTenantSelector from "~/areas/projects/components/Releases/Deployments/EnvironmentAndTenantSelector/EnvironmentAndTenantSelector";
import type { DynamicEnvironmentSelection, EnvironmentSelection, NewEnvironmentSelection, StaticEnvironmentSelection } from "~/areas/projects/components/Releases/Deployments/EnvironmentAndTenantSelector/EnvironmentSelection";
import { getDynamicEnvironments, getNewEnvironments, getEnvironmentIds, getStaticEnvironments } from "~/areas/projects/components/Releases/Deployments/EnvironmentAndTenantSelector/EnvironmentSelection";
import PackageDeploymentOptions from "~/areas/projects/components/Releases/Deployments/PackageDeploymentOptions";
import PendingInterruptions from "~/areas/projects/components/Releases/Deployments/PendingInterruptions";
import { useProjectContext } from "~/areas/projects/context";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { client, repository } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent/Errors";
import { createErrorsFromOctopusError } from "~/components/DataBaseComponent/Errors";
import type { ProgressDialogStatus } from "~/components/Dialog/ProgressDialog";
import ProgressDialog from "~/components/Dialog/ProgressDialog";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent/FormBaseComponent";
import matchErrorsToFieldNames from "~/components/FormBaseComponent/matchErrorsToFieldNames";
import FormPaperLayout from "~/components/FormPaperLayout/FormPaperLayout";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import routeLinks from "~/routeLinks";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import { ProjectStatus } from "../../ProjectStatus/ProjectStatus";
import { DeploymentModelType } from "../../Runbooks/RunbookRunNowLayout";
import { DeploymentCreateGoal } from "../ReleasesRoutes/releaseRouteLinks";
import FailureMode from "./FailureMode";
import { default as NowOrLater, NowOrLaterEnum } from "./NowOrLater/NowOrLater";
import PackageDownloadOptions from "./PackageDownloadOptions";
import type { DeploymentMachineInfo } from "./Preview";
import { DeploymentPreview, DeploymentType } from "./Preview";
import PromptVariables from "./PromptVariables";
import CurrentVersionMap from "./currentVersionMap";
import type { DeploymentRequestModel } from "./deploymentRequestModel";
import { loadPendingInterruptions } from "./pendingInterruptionUtil";
type DeploymentCreateRouteParams = {
    previousId?: string;
    goal?: DeploymentCreateGoal;
    releaseVersion?: string;
    tenantIds?: string;
    tags?: string;
} & ProjectRouteParams;
type DeploymentCreateProps = RouteComponentProps<DeploymentCreateRouteParams>;
interface DeploymentCreatePropsInternal extends DeploymentCreateProps, WithProjectContextInjectedProps {
    trackAction: AnalyticTrackedActionDispatcher;
    dynamicEnvironmentsEnabled: boolean;
}
export type PromotionsMap = {
    [id: string]: DeploymentPromotionTarget | DeploymentPromotionTenant;
};
interface AvailableDeploymentsApiResults {
    allowDeployment: boolean;
    previews: Map<string, GetBffDeploymentPreview>;
    promptVariablesForm: Form;
    deployments: DeploymentRequestModel[];
}
//eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DeploymentModel {
}
interface DeploymentCreateState extends OptionalFormBaseComponentState<DeploymentModel> {
    previousDeployment: DeploymentResource;
    nowOrLater: NowOrLaterEnum;
    forcePackageDownload: boolean;
    forcePackageRedeployment: boolean;
    defaultGuidedFailureMode: GuidedFailureMode;
    guidedFailureMode: GuidedFailureMode;
    actionIdsToSkip: string[];
    deployments: DeploymentRequestModel[];
    queueTime: Moment;
    queueTimeExpiry: Moment;
    selectedEnvironments: EnvironmentSelection[];
    selectedTenantIds: string[];
    redirectPath?: string;
    promptVariablesForm: Form;
    promotionsMap: PromotionsMap;
    template: DeploymentTemplateResource;
    missingTenantVariables: Dictionary<string[]>;
    allEnvironments: EnvironmentResource[];
    allDynamicEnvironments: DynamicEnvironmentResource[];
    allTenants: TenantResource[];
    pendingInterruptions: Array<TaskResource<any>>;
    goal: string;
    releaseVersion: string;
    previousDeploymentId: string;
    project: ProjectResource;
    deploymentSettings: DeploymentSettingsResource;
    release: ReleaseResource;
    channel: ChannelResource;
    currentVersionMap: CurrentVersionMap;
    latestReleases: LatestReleaseResource[];
    previews: Map<string, GetBffDeploymentPreview>;
    previousDeploymentBeingRetried?: DeploymentResource;
    numOfDeploymentsToBeCreated: number | null;
    numOfDeploymentsCreated: number;
}
const MaximumInterruptionsToLoad = 10;
const NewDynamicEnvironmentId = "NewDynamicEnvironmentId";
export const AuditTrailLink: React.SFC<{
    link: string;
}> = ({ link, children }) => {
    return <PermissionCheck permission={Permission.EventView} wildcard={true} render={() => <InternalLink to={link}>{children}</InternalLink>}/>;
};
AuditTrailLink.displayName = "AuditTrailLink"
export const ModifiedProperty: React.SFC<{
    itemLink: React.ComponentType<any>;
    description: string;
}> = ({ itemLink: ItemLink, description }) => (<strong>
        {description} (<ItemLink />)
    </strong>);
ModifiedProperty.displayName = "ModifiedProperty"
export const CsvSeparated: React.FC = (props) => {
    const children = React.Children.toArray(props.children).reduce((prev, current, index) => {
        return [...React.Children.toArray(prev), index !== 0 ? ", " : null, current];
    }, []);
    return <React.Fragment>{children}</React.Fragment>;
};
CsvSeparated.displayName = "CsvSeparated"
class DeploymentCreateInternal extends FormBaseComponent<DeploymentCreatePropsInternal, DeploymentCreateState, DeploymentModel> {
    private buildDeploymentInfoRaceConditioner = new RequestRaceConditioner();
    constructor(props: DeploymentCreatePropsInternal) {
        super(props);
        const goal = this.props.match.params.goal!;
        const releaseVersion = this.props.match.params.releaseVersion!;
        const previousDeploymentId = goal === DeploymentCreateGoal.TryAgain ? this.props.match.params.previousId! : null!;
        this.state = {
            previousDeployment: null!,
            missingTenantVariables: {},
            nowOrLater: NowOrLaterEnum.Now,
            forcePackageDownload: false,
            forcePackageRedeployment: false,
            defaultGuidedFailureMode: GuidedFailureMode.EnvironmentDefault,
            guidedFailureMode: GuidedFailureMode.EnvironmentDefault,
            actionIdsToSkip: [],
            deployments: [],
            queueTime: null!,
            queueTimeExpiry: null!,
            selectedEnvironments: [],
            selectedTenantIds: [],
            promptVariablesForm: null!,
            promotionsMap: null!,
            template: null!,
            allEnvironments: [],
            allDynamicEnvironments: [],
            allTenants: [],
            pendingInterruptions: [],
            goal,
            releaseVersion,
            previousDeploymentId,
            latestReleases: [],
            project: null!,
            deploymentSettings: null!,
            release: null!,
            channel: null!,
            currentVersionMap: null!,
            previews: new Map<string, GetBffDeploymentPreview>(),
            previousDeploymentBeingRetried: null!,
            numOfDeploymentsToBeCreated: null,
            numOfDeploymentsCreated: 0,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const allEnvsPromise = this.loadAllEnvironments();
            const allDynamicEnvironmentsPromise = this.loadAllDynamicEnvironments();
            const previousDeployment = this.state.previousDeploymentId ? await repository.Deployments.get(this.state.previousDeploymentId) : null!;
            const project = this.props.projectContext.state.model;
            const allTenantsPromise = this.loadAllTenants(project);
            const missingTenantVariablesPromise = this.loadMissingTenantVariables(project);
            const release = await repository.Projects.getReleaseByVersion(project, this.state.releaseVersion);
            const channelPromise = repository.Releases.getChannel(release);
            const latestReleases = await repository.Releases.getLatest(project);
            const currentVersionMap = CurrentVersionMap.CreateFromLatestReleases(latestReleases);
            const template = await repository.Releases.getDeploymentTemplate(release);
            const deploymentSettings = await this.LoadDeploymentSettings(project, release);
            const isRetry = previousDeployment && this.state.goal === DeploymentCreateGoal.TryAgain;
            const guidedFailureMode = isRetry ? (previousDeployment.UseGuidedFailure ? GuidedFailureMode.On : GuidedFailureMode.Off) : deploymentSettings.DefaultGuidedFailureMode;
            const actionIdsToSkip = isRetry && previousDeployment.SkipActions.length > 0 ? previousDeployment.SkipActions : [];
            const forcePackageDownload = isRetry ? previousDeployment.ForcePackageDownload : deploymentSettings.ForcePackageDownload;
            this.setState({
                template,
                promotionsMap: this.buildPromotionsMap(template),
                previousDeployment,
                defaultGuidedFailureMode: deploymentSettings.DefaultGuidedFailureMode,
                guidedFailureMode,
                actionIdsToSkip,
                missingTenantVariables: await missingTenantVariablesPromise,
                allEnvironments: await allEnvsPromise,
                allDynamicEnvironments: await allDynamicEnvironmentsPromise,
                allTenants: await allTenantsPromise,
                project,
                deploymentSettings,
                release,
                channel: await channelPromise,
                latestReleases,
                currentVersionMap,
                forcePackageDownload,
                previousDeploymentBeingRetried: isRetry ? previousDeployment : null!,
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        if (this.state.redirectPath) {
            return <InternalRedirect to={this.state.redirectPath} push={true}/>;
        }
        // If the user attempts to create deployments and one or more fail, the primary-action becomes "Deploy Unsuccessful"
        // This allows retrying only the failed attempts
        let onSaveLabel: string;
        let onSaveClick: () => Promise<void>;
        let secondaryButton: React.ReactNode;
        if (!this.hasFailedAttempts()) {
            onSaveLabel = "Deploy";
            onSaveClick = () => this.deploy();
            secondaryButton = null;
        }
        else {
            onSaveLabel = "Retry Unsuccessful";
            onSaveClick = () => this.deploy(true);
            secondaryButton = <ActionButton label={"Deploy All"} type={ActionButtonType.Secondary} onClick={() => this.deploy(false, true)}/>;
        }
        const isSaveEnabled = !this.state.busy && this.canDeploy(this.state.selectedEnvironments, this.state.selectedTenantIds);
        return (<>
                <FormPaperLayout busy={this.state.busy} errors={this.errors} title={`Deploy release ${this.state.release ? this.state.release.Version : ""}`} breadcrumbTitle={`Release ${this.state.release ? this.state.release.Version : ""}`} breadcrumbPath={routeLinks.project(this.props.match.params.projectSlug).deployments.release(this.state.release).root} model={this.state.model} cleanModel={this.state.cleanModel} onSaveClick={onSaveClick} saveButtonLabel={onSaveLabel} savePermission={{ permission: Permission.DeploymentCreate, environment: "*", tenant: "*", project: this.state.project && this.state.project.Id }} saveText="" forceDisableFormSaveButton={!isSaveEnabled} disableDirtyFormChecking={true} secondaryAction={secondaryButton} statusSection={<ProjectStatus doBusyTask={this.doBusyTask}/>}>
                    {this.deploymentConfigurationForm()}
                </FormPaperLayout>
                {this.progressDialog()}
            </>);
    }
    private progressDialog() {
        const { numOfDeploymentsToBeCreated, numOfDeploymentsCreated } = this.state;
        const singleDeployment = numOfDeploymentsToBeCreated === 1;
        const title = `Creating ${numOfDeploymentsToBeCreated} deployment${singleDeployment ? "" : "s"}`;
        const content = `Please wait while the deployment${singleDeployment ? " is" : "s are"} created.`;
        const progressStatus: ProgressDialogStatus = numOfDeploymentsToBeCreated === null ? { type: "not in progress" } : { type: "in progress", percentComplete: Math.min((numOfDeploymentsCreated / numOfDeploymentsToBeCreated) * 100, 100) };
        return <ProgressDialog title={title} content={content} status={progressStatus}/>;
    }
    private deploymentConfigurationForm() {
        const template = this.state.template;
        const deploymentSettings = this.state.deploymentSettings;
        const selectedEnvironmentIds = getEnvironmentIds(this.state.selectedEnvironments);
        const tenantsWithMissingVariables = ((selectedEnvironmentIds.length > 0 && this.state.missingTenantVariables[selectedEnvironmentIds[0]]) || []).filter((tenantId) => this.state.selectedTenantIds.indexOf(tenantId) !== -1);
        const selectedEnvironmentsWithMissingDynamicInfrastructure = deploymentSettings && deploymentSettings.ConnectivityPolicy && deploymentSettings.ConnectivityPolicy.AllowDeploymentsToNoTargets === false
            ? []
            : this.state.allEnvironments.filter((e) => e.AllowDynamicInfrastructure === false && selectedEnvironmentIds.indexOf(e.Id) > -1);
        return (<div>
                {this.state && this.state.release && (<div>
                        {template && (!template.PromoteTo || template.PromoteTo.length === 0) && (<Callout title="Note" type={CalloutType.Warning}>
                                Before you can deploy this release, you need to <InternalLink to={routeLinks.infrastructure.environments.root}>add an environment</InternalLink> to deploy it to.
                            </Callout>)}
                        {selectedEnvironmentsWithMissingDynamicInfrastructure.length > 0 && (<Callout title="Dynamic Infrastructure Note" type={CalloutType.Information}>
                                This project allows deployments to be created when there are no deployment targets, but the following environments do not allow dynamic targets to be created. Please note that this may cause an error during deployment
                                if you're using <ExternalLink href="EnvironmentDynamicInfrastructure">Dynamic Infrastructure</ExternalLink>.<div>You can opt into dynamic infrastructure for a given environment from the link(s) below:</div>
                                <div>
                                    {selectedEnvironmentsWithMissingDynamicInfrastructure.map((env) => (<span>
                                            <InternalLink key={env.Id} to={routeLinks.infrastructure.environment(env)} openInSelf={false}>
                                                {env.Name}
                                            </InternalLink>
                                            &nbsp;
                                        </span>))}
                                </div>
                            </Callout>)}
                        <PendingInterruptions pendingInterruptions={this.state.pendingInterruptions}/>
                        {template && (<EnvironmentAndTenantSelector project={this.state.project} template={template} channel={this.state.channel} previousDeployment={this.state.previousDeployment} tenantedDeploymentMode={this.state.project.TenantedDeploymentMode} onSelectionUpdated={this.onSelectionUpdated} tenantsWithMissingVariables={tenantsWithMissingVariables} onDoingBusyTask={this.doBusyTask} release={this.state.release} latestReleases={this.state.latestReleases} allTenants={this.state.allTenants} allEnvironments={this.state.allEnvironments} allDynamicEnvironments={this.state.allDynamicEnvironments} goal={this.props.match.params.goal} previousId={this.props.match.params.previousId} tenantIds={this.props.match.params.tenantIds} tags={this.props.match.params.tags} search={this.props.location.search}/>)}

                        {this.state.promptVariablesForm && this.state.promptVariablesForm.Elements.length > 0 && (<PromptVariables form={this.state.promptVariablesForm} onParameterChanged={(variable) => {
                        const promptVariablesForm = { ...this.state.promptVariablesForm };
                        promptVariablesForm.Values[variable.VariableName] = variable.Value;
                        this.setState({ promptVariablesForm });
                    }} processType={ProcessType.Deployment}/>)}

                        <NowOrLater onScheduleDatesSet={this.onDeploymentScheduleChanged} modelType={DeploymentModelType.Deployment}/>

                        <ActionToggle repository={this.props.projectContext.state.projectContextRepository} selectedEnvironmentIds={getEnvironmentIds(this.state.selectedEnvironments)} previews={Array.from(this.state.previews.values())} release={this.state.release} actionIds={this.state.actionIdsToSkip} onActionIdsChanged={this.onActionIdsToSkipChanged}/>

                        <FailureMode defaultGuidedFailureMode={this.state.defaultGuidedFailureMode} guidedFailureMode={this.state.guidedFailureMode} onModeChanged={(guidedFailureMode) => this.setState({ guidedFailureMode })} modelType={DeploymentModelType.Deployment}/>

                        <PackageDownloadOptions forcePackageDownload={this.state.forcePackageDownload} onOptionChanged={this.onPackageDownloadOptionChanged}/>

                        {this.state.deploymentSettings.DefaultToSkipIfAlreadyInstalled && <PackageDeploymentOptions forcePackageRedeployment={this.state.forcePackageRedeployment} onChange={this.onPackageReDeploymentOptionChanged}/>}

                        {this.state.deployments.length > 0 && (<DeploymentPreview release={this.state.release} getDeploymentPreview={this.getDeploymentPreview} deployments={this.state.deployments} stepActionIdsToSkip={this.state.actionIdsToSkip} tenantedDeploymentMode={this.state.project.TenantedDeploymentMode} promptVariableForm={this.state.promptVariablesForm} onExcludeSpecificMachinesSelected={this.onExcludeSpecificMachinesSelected} onIncludeSpecificMachinesSelected={this.onIncludeSpecificMachinesSelected} onAllTargetsSelected={this.onAllTargetsSelected} tenantsWithMissingVariables={tenantsWithMissingVariables} onDoingBusyTask={this.doBusyTask} allTenants={this.state.allTenants} modelType={DeploymentModelType.Deployment}/>)}
                    </div>)}
            </div>);
    }
    private getDeploymentPreview = (environmentId: string, tenantId: string) => {
        return this.state.previews.get(`${environmentId || ""}${tenantId || ""}`);
    };
    private canDeploy(selectedEnvironments: EnvironmentSelection[], resultantTenants: string[]): boolean {
        if (!this.state.project) {
            return false;
        }
        const staticEnvironments = getStaticEnvironments(selectedEnvironments);
        const dynamicEnvironments = getDynamicEnvironments(selectedEnvironments);
        const newEnvironments = getNewEnvironments(selectedEnvironments);
        if (newEnvironments.length > 0)
            return true; // todo @dynamic-deployments: Need to consider tenants here
        const environmentSelected = staticEnvironments.length > 0 || dynamicEnvironments.length > 0;
        const tenantSelected = resultantTenants && resultantTenants.length > 0;
        switch (this.state.project.TenantedDeploymentMode) {
            case TenantedDeploymentMode.TenantedOrUntenanted:
                return environmentSelected || tenantSelected;
            case TenantedDeploymentMode.Untenanted:
                return environmentSelected && !tenantSelected;
            case TenantedDeploymentMode.Tenanted:
                return environmentSelected && tenantSelected;
            default:
                throw new Error("TenantedDeploymentMode not recognized");
        }
    }
    private buildPromotionsMap(template: DeploymentTemplateResource) {
        const promotionsMap: PromotionsMap = {};
        _.each(template.PromoteTo, (environmentPromotion) => {
            promotionsMap[environmentPromotion.Id] = environmentPromotion;
        });
        _.each(template.TenantPromotions, (tenantPromotion) => {
            promotionsMap[tenantPromotion.Id] = tenantPromotion;
        });
        return promotionsMap;
    }
    private async loadMissingTenantVariables(project: ProjectResource): Promise<Dictionary<string[]>> {
        if (project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted) {
            return Promise.resolve({});
        }
        const missingTenantVariables = await repository.Tenants.missingVariables({ projectId: project.Id }, true);
        const missingVariables: Dictionary<string[]> = {};
        missingTenantVariables.forEach((t) => {
            t.MissingVariables.forEach((mv) => {
                const newVals = missingVariables[mv.EnvironmentId!] || [];
                newVals.push(t.TenantId);
                missingVariables[mv.EnvironmentId!] = _.uniq(newVals);
            });
        });
        return missingVariables;
    }
    private async deploy(retry = false, all = false): Promise<void> {
        const ev: ActionEvent = {
            action: Action.Deploy,
            resource: "Deploy Release",
        };
        const gitRef = this.state.release.VersionControlReference?.GitRef;
        if (HasGitPersistenceSettings(this.state.project.PersistenceSettings) && gitRef) {
            ev.isDefaultBranch = IsDefaultBranch(this.state.project, gitRef);
            ev.gitRefType = getGitRefType(gitRef);
        }
        let wasDeployed = false;
        await this.doBusyTask(async () => {
            await this.props.trackAction("Trigger Deployment", ev, async (cb: AnalyticErrorCallback) => {
                const deploymentsBeingCreated = retry ? this.state.deployments.filter((d) => !(d.response as DeploymentResource).TaskId).length : this.state.deployments.length;
                this.setState({ numOfDeploymentsToBeCreated: deploymentsBeingCreated });
                const deploymentPromises = [];
                const deployments = _.cloneDeep(this.state.deployments);
                const environmentCreationErrors: Errors[] = [];
                for (const deployment of deployments) {
                    if (deployment.environment.type === "New") {
                        try {
                            // create a new environment and set it's id into the request
                            const newEnvironment = await repository.DynamicEnvironments.create({
                                Name: deployment.environment.name,
                                Description: deployment.environment.name,
                            });
                            deployment.request.EnvironmentId = newEnvironment.Id;
                        }
                        catch (ex) {
                            const error = createErrorsFromOctopusError(ex);
                            const arbitraryErrors = error.errors.reduce((prev, current, index) => ({ ...prev, [`error_${index}`]: current }), {});
                            error.fieldErrors = { ...arbitraryErrors, ...matchErrorsToFieldNames(ex, this.state.model) };
                            environmentCreationErrors.push(error);
                        }
                    }
                }
                if (environmentCreationErrors.length > 0) {
                    if (environmentCreationErrors.length === 1) {
                        this.setValidationErrors(environmentCreationErrors[0].message, environmentCreationErrors[0].fieldErrors);
                    }
                    else {
                        this.setValidationErrors(`${environmentCreationErrors.length} errors occurred while attempting to create new dynamic environments for deployment. See the Deployments section below for the error details.`);
                    }
                    for (const e of environmentCreationErrors) {
                        cb(e);
                    }
                    // Reset the dialog and don't create any deployments because we couldn't create environments
                    this.setState({ numOfDeploymentsToBeCreated: null, numOfDeploymentsCreated: 0 });
                    return;
                }
                await this.setUseGuidedFailure(deployments);
                //Fire off a max of 10 deployment requests at any one time, so we don't bombard the server if there are many environments/tenants
                const throttle = PLimit.default(10);
                const deploymentErrors: Errors[] = [];
                for (const record of deployments) {
                    // If retrying, only process previously failed
                    if (retry) {
                        if (!record.response || !this.isError(record.response)) {
                            continue;
                        }
                    }
                    record.request.FormValues = this.state.promptVariablesForm ? this.state.promptVariablesForm.Values : null;
                    deploymentPromises.push(throttle(() => repository.Deployments.create(record.request as any)
                        .then((deployment) => {
                        record.response = deployment;
                        this.setState({ numOfDeploymentsCreated: this.state.numOfDeploymentsCreated + 1 });
                    })
                        .catch((ex) => {
                        const error = createErrorsFromOctopusError(ex);
                        const arbitraryErrors = error.errors.reduce((prev, current, index) => ({ ...prev, [`error_${index}`]: current }), {});
                        error.fieldErrors = { ...arbitraryErrors, ...matchErrorsToFieldNames(ex, this.state.model) };
                        deploymentErrors.push(error);
                        record.response = ex;
                    })));
                }
                await Promise.all(deploymentPromises);
                // Reset number of deployments to close dialog
                this.setState({ numOfDeploymentsToBeCreated: null, numOfDeploymentsCreated: 0 });
                if (deployments.length === 1 && (deployments[0].response as DeploymentResource).TaskId) {
                    // If creating a single deployment was successful, navigate to the task details for that deployment
                    const redirectPath = routeLinks
                        .project(this.state.project)
                        .release(this.state.release)
                        .deployments.specific(deployments[0].response as DeploymentResource);
                    this.setState({ redirectPath });
                    wasDeployed = true;
                }
                else if (_.every(deployments, (result) => !!(result.response as DeploymentResource).TaskId)) {
                    // If creating multiple deployments were all successful, navigate to the task list page filtered
                    // to show the created deployment tasks
                    const taskIds = _.map(deployments, (result) => (result.response as DeploymentResource).TaskId);
                    this.setState({ redirectPath: routeLinks.tasks.filtered({ ids: taskIds, spaces: [repository.spaceId!], includeSystem: false }) });
                    wasDeployed = true;
                }
                else {
                    // Otherwise there was at least one error when creating the deployment/s
                    if (deploymentErrors.length === 1) {
                        this.setValidationErrors(deploymentErrors[0].message, deploymentErrors[0].fieldErrors);
                        // If there was a single error then the error details at the top of the page
                    }
                    else {
                        // If there were multiple errors, show a generic message at the top of the page
                        // The individual error details will be shown in the deployments section
                        this.setValidationErrors(`${deploymentErrors.length} errors occurred while attempting to create the deployments.  See the Deployments section below for the error details.`);
                    }
                    this.setState({ deployments });
                    for (const e of deploymentErrors) {
                        cb(e);
                    }
                }
            });
        });
    }
    private createDeploymentsForStaticEnvironments(environments: StaticEnvironmentSelection[], tenantIds: string[], promptVariablesForm: Form) {
        const results = [];
        if (environments.length === 0) {
            return [];
        }
        if (tenantIds.length > 0) {
            for (const tenantId of tenantIds) {
                results.push(this.createDeploymentRequestForStaticEnvironment(environments[0], tenantId, promptVariablesForm));
            }
        }
        else {
            if (this.state.project && this.state.project.TenantedDeploymentMode !== TenantedDeploymentMode.Tenanted) {
                for (const environment of environments) {
                    results.push(this.createDeploymentRequestForStaticEnvironment(environment, null!, promptVariablesForm));
                }
            }
        }
        return results;
    }
    private async loadDeploymentPreviewsForStaticEnvironments(environmentIds: string[], tenantIds: string[]) {
        const map = new Map<string, GetBffDeploymentPreview>();
        let keys: string[] = [];
        let values: GetBffDeploymentPreview[] = [];
        // If tenants have been selected then we use the tenant-environment deployment-previews
        if (tenantIds && tenantIds.length > 0) {
            [keys, values] = await this.getTenantEnvironmentPreviews(environmentIds, tenantIds);
        }
        else {
            environmentIds.filter((environmentId) => this.state.promotionsMap[environmentId]).map((environmentId) => keys.push(environmentId));
            const response = await repository.Releases.getDeploymentPreviewsBff(this.state.release, environmentIds, []);
            values = response.Previews;
        }
        for (let index = 0; index < keys.length; index++) {
            map.set(keys[index], values[index]);
        }
        return map;
    }
    private async loadDeploymentPreviewsForDynamicEnvironments(environmentIds: string[]) {
        const map = new Map<string, GetBffDeploymentPreview>();
        if (environmentIds.length === 0)
            return map;
        const keys: string[] = [];
        let values: GetBffDeploymentPreview[] = [];
        environmentIds.map((e) => keys.push(e));
        const response = await repository.Releases.getDeploymentPreviewsBff(this.state.release, environmentIds, []);
        values = response.Previews;
        for (let index = 0; index < keys.length; index++) {
            map.set(keys[index], values[index]);
        }
        return map;
    }
    // Returns promises for deployment-previews for the combination of selected tenants and environments
    private async getTenantEnvironmentPreviews(environmentIds: string[], tenantIds: string[]): Promise<[
        string[],
        GetBffDeploymentPreview[]
    ]> {
        const keys: string[] = [];
        const eIds: string[] = [];
        const tIds = _.flatten(tenantIds.map((tenantId) => {
            const dpt = this.state.promotionsMap[tenantId] as DeploymentPromotionTenant;
            return dpt.PromoteTo.filter((tenantEnvironmentPromotion) => environmentIds.includes(tenantEnvironmentPromotion.Id)).map((tenantEnvironmentPromotion) => {
                keys.push(tenantEnvironmentPromotion.Id + tenantId);
                eIds.push(tenantEnvironmentPromotion.Id);
                return tenantId;
            });
        }));
        const response = await repository.Releases.getDeploymentPreviewsBff(this.state.release, [...new Set(eIds)], tIds);
        const values = response.Previews;
        return [keys, values];
    }
    private async LoadGitRefResource(project: ProjectResource, release: ReleaseResource) {
        const gitRef = toGitBranchWhenUnknown(release.VersionControlReference?.GitRef);
        if (!!gitRef) {
            return await repository.Projects.getGitRef(project, gitRef);
        }
        // If the current release doesn't have a GitRef, but the project is version controlled
        // then the release must have been created before conversion
        // We fall back to the default branch since that should be considered the closest to the "current details" had it not been version controlled.
        if (HasGitPersistenceSettings(project.PersistenceSettings)) {
            return await repository.Projects.getBranch(project, project.PersistenceSettings.DefaultBranch);
        }
        return undefined;
    }
    private async LoadDeploymentSettings(project: ProjectResource, release: ReleaseResource) {
        const gitRefResource = await this.LoadGitRefResource(project, release);
        const projectContextRepository = new ProjectContextRepository(client, this.props.projectContext.state.model, gitRefResource);
        return await projectContextRepository.DeploymentSettings.get();
    }
    private loadFormDetails(previews: Map<string, GetBffDeploymentPreview>) {
        const form: Form = { Elements: [], Values: {} };
        previews.forEach((preview: GetBffDeploymentPreview) => {
            if (!preview || !preview.Form) {
                return;
            }
            if (preview.Form.Values) {
                _.each(preview.Form.Values, (v, k) => {
                    form.Values[k] = v;
                });
            }
            if (preview.Form.Elements) {
                preview.Form.Elements.forEach((c) => {
                    if (!form.Elements.find((e: FormElement) => {
                        return e.Name === c.Name;
                    })) {
                        form.Elements.push(c);
                    }
                });
            }
        });
        return form;
    }
    private createDeploymentRequestForStaticEnvironment(environment: StaticEnvironmentSelection, tenantId: string, promptVariablesForm: Form): DeploymentRequestModel {
        const isRetryingInThisScope = this.state.previousDeploymentBeingRetried && this.state.previousDeploymentBeingRetried.EnvironmentId === environment.environmentId && this.state.previousDeploymentBeingRetried.TenantId === tenantId;
        const specificMachineIds = isRetryingInThisScope && this.state.previousDeploymentBeingRetried!.SpecificMachineIds.length > 0 ? this.state.previousDeploymentBeingRetried!.SpecificMachineIds : [];
        const excludeMachineIds = isRetryingInThisScope && this.state.previousDeploymentBeingRetried!.ExcludedMachineIds.length > 0 ? this.state.previousDeploymentBeingRetried!.ExcludedMachineIds : [];
        const request: CreateDeploymentResource = {
            ReleaseId: this.state.release.Id,
            EnvironmentId: environment.environmentId,
            ProjectId: this.state.project.Id,
            TenantId: tenantId,
            SkipActions: this.state.actionIdsToSkip,
            QueueTime: this.state.queueTime,
            QueueTimeExpiry: this.state.queueTimeExpiry,
            FormValues: promptVariablesForm ? promptVariablesForm.Values : null,
            ForcePackageDownload: this.state.forcePackageDownload,
            UseGuidedFailure: false,
            SpecificMachineIds: specificMachineIds,
            ExcludedMachineIds: excludeMachineIds,
            ForcePackageRedeployment: this.state.forcePackageRedeployment,
        };
        return {
            tenantId,
            environment,
            request,
            currentVersion: this.state.currentVersionMap.getCurrentRelease(environment.environmentId, tenantId)!,
        };
    }
    private createDeploymentRequestForNewDynamicEnvironment(environment: NewEnvironmentSelection, index: number, promptVariablesForm: Form): DeploymentRequestModel {
        const tenantId = undefined;
        const environmentId = `${NewDynamicEnvironmentId}${index}`;
        const request: CreateDeploymentResource = {
            ReleaseId: this.state.release.Id,
            EnvironmentId: environmentId,
            ProjectId: this.state.project.Id,
            TenantId: tenantId,
            SkipActions: this.state.actionIdsToSkip,
            QueueTime: this.state.queueTime,
            QueueTimeExpiry: this.state.queueTimeExpiry,
            FormValues: promptVariablesForm ? promptVariablesForm.Values : null,
            ForcePackageDownload: this.state.forcePackageDownload,
            UseGuidedFailure: false,
            SpecificMachineIds: [],
            ExcludedMachineIds: [],
            ForcePackageRedeployment: this.state.forcePackageRedeployment,
        };
        return {
            tenantId,
            environment,
            request,
            currentVersion: this.state.currentVersionMap.getCurrentRelease(environmentId, tenantId)!,
        };
    }
    private createDeploymentRequestForExistingDynamicEnvironment(environment: DynamicEnvironmentSelection, promptVariablesForm: Form): DeploymentRequestModel {
        const tenantId = undefined;
        const request: CreateDeploymentResource = {
            ReleaseId: this.state.release.Id,
            EnvironmentId: environment.environmentId,
            ProjectId: this.state.project.Id,
            TenantId: tenantId,
            SkipActions: this.state.actionIdsToSkip,
            QueueTime: this.state.queueTime,
            QueueTimeExpiry: this.state.queueTimeExpiry,
            FormValues: promptVariablesForm ? promptVariablesForm.Values : null,
            ForcePackageDownload: this.state.forcePackageDownload,
            UseGuidedFailure: false,
            SpecificMachineIds: [],
            ExcludedMachineIds: [],
            ForcePackageRedeployment: this.state.forcePackageRedeployment,
        };
        return {
            tenantId,
            environment,
            request,
            currentVersion: this.state.currentVersionMap.getCurrentRelease(environment.environmentId, tenantId)!,
        };
    }
    private async getAvailableDeploymentsFromApi(environments: StaticEnvironmentSelection[], tenantIds: string[], tenantTagsUsed: boolean): Promise<AvailableDeploymentsApiResults> {
        const previews = await this.loadDeploymentPreviewsForStaticEnvironments(environments.map((e) => e.environmentId), tenantIds);
        const promptVariablesForm = this.loadFormDetails(previews);
        // If the selected tenant-tags did not match any tenants, then we want to ensure checkCanDeploy is false and that
        // there are no deployments created
        if (tenantTagsUsed && tenantIds.length === 0) {
            return {
                previews,
                allowDeployment: false,
                promptVariablesForm,
                deployments: [],
            };
        }
        const deployments = this.createDeploymentsForStaticEnvironments(environments, tenantIds, promptVariablesForm);
        return {
            previews,
            allowDeployment: true,
            promptVariablesForm,
            deployments,
        };
    }
    private onSelectionUpdated = async (environments: EnvironmentSelection[], tenantIds: string[], tenantTagsUsed: boolean) => {
        const previews = new Map<string, GetBffDeploymentPreview>();
        const deployments: DeploymentRequestModel[] = [];
        const staticEnvironments = getStaticEnvironments(environments);
        const dynamicEnvironments = getDynamicEnvironments(environments);
        const newEnvironments = getNewEnvironments(environments);
        const environmentIds = getEnvironmentIds(environments);
        await this.doBusyTask(async () => {
            if (staticEnvironments.length > 0) {
                await this.buildDeploymentInfoRaceConditioner.avoidStaleResponsesForRequest(this.getAvailableDeploymentsFromApi(staticEnvironments, tenantIds, tenantTagsUsed), (apiResults) => {
                    if (apiResults.allowDeployment) {
                        apiResults.previews.forEach((preview, key) => {
                            previews.set(key, preview);
                        });
                        deployments.push(...apiResults.deployments);
                    }
                });
            }
            if (dynamicEnvironments.length > 0) {
                const dynamicEnvironmentPreviews = await this.loadDeploymentPreviewsForDynamicEnvironments(dynamicEnvironments.map((e) => e.environmentId));
                dynamicEnvironmentPreviews.forEach((preview, key) => {
                    previews.set(key, preview);
                });
                for (const environment of dynamicEnvironments) {
                    deployments.push(this.createDeploymentRequestForExistingDynamicEnvironment(environment, this.loadFormDetails(dynamicEnvironmentPreviews)));
                }
            }
            if (newEnvironments.length > 0) {
                for (let index = 0; index < newEnvironments.length; index++) {
                    const newEnvironment = newEnvironments[index];
                    // todo-dynamic-environments: Get the preview for a new dynamic environment from the api
                    previews.set(`${NewDynamicEnvironmentId}${index}`, {
                        WorkItemsCount: 0,
                        ReleaseCount: 0,
                        ChangesMarkdown: "",
                        Form: {
                            Elements: [],
                            Values: {},
                        },
                        Links: {},
                        StepsToExecute: [],
                        UseGuidedFailureModeByDefault: false,
                    });
                    deployments.push(this.createDeploymentRequestForNewDynamicEnvironment(newEnvironment, index, {
                        Elements: [],
                        Values: {},
                    }));
                }
            }
            let pendingInterruptions: Array<TaskResource<any>> = [];
            // We only load interruptions if the number of deployments is low, see https://github.com/OctopusDeploy/Issues/issues/4415
            if (deployments.length < MaximumInterruptionsToLoad) {
                pendingInterruptions = await loadPendingInterruptions(this.state.project.Id, deployments
                    .filter((d) => d.environment.type !== "New")
                    .map((d) => {
                    return { EnvironmentId: d.request.EnvironmentId, TenantId: d.tenantId };
                }));
            }
            this.setState({
                previews: previews,
                selectedEnvironments: environments,
                selectedTenantIds: tenantIds,
                deployments: deployments,
                promptVariablesForm: this.loadFormDetails(previews),
                pendingInterruptions: pendingInterruptions || [],
                actionIdsToSkip: environmentIds.length === 0 ? [] : this.state.actionIdsToSkip,
            });
        }, { timeOperationOptions: timeOperationOptions.for("GetPreview") });
    };
    private async setUseGuidedFailure(deploymentRequests: DeploymentRequestModel[]) {
        const mode = this.state.guidedFailureMode;
        if (deploymentRequests.length > 0) {
            if (mode === GuidedFailureMode.EnvironmentDefault) {
                const deploymentsByEnvironment = _.groupBy(deploymentRequests, (x) => x.request.EnvironmentId);
                const environmentIds = _.chain(deploymentRequests)
                    .map((x) => x.request.EnvironmentId)
                    .uniq()
                    .value();
                for (const environmentId of environmentIds) {
                    const environmentType = deploymentsByEnvironment[environmentId][0].environment.type;
                    if (environmentType === "Static") {
                        const environment = await repository.Environments.get(environmentId);
                        for (const deployment of deploymentsByEnvironment[environmentId]) {
                            deployment.request.UseGuidedFailure = environment.UseGuidedFailure;
                        }
                    }
                    else {
                        // todo @team-dynamic-deployments: Set guided failure properly here
                        for (const deployment of deploymentsByEnvironment[environmentId]) {
                            deployment.request.UseGuidedFailure = false;
                        }
                    }
                }
            }
            else {
                for (const deployment of deploymentRequests) {
                    deployment.request.UseGuidedFailure = mode === GuidedFailureMode.On;
                }
            }
        }
    }
    private onPackageDownloadOptionChanged = (forcePackageDownload: boolean) => {
        const deployments = _.cloneDeep(this.state.deployments);
        deployments.forEach((deployment) => (deployment.request.ForcePackageDownload = forcePackageDownload));
        this.setState({ deployments, forcePackageDownload });
    };
    private onPackageReDeploymentOptionChanged = (forcePackageRedeployment: boolean) => {
        const deployments = _.cloneDeep(this.state.deployments);
        deployments.forEach((deployment) => (deployment.request.ForcePackageRedeployment = forcePackageRedeployment));
        this.setState({ deployments, forcePackageRedeployment });
    };
    private onDeploymentScheduleChanged = (queueTime: Moment, queueTimeExpiry: Moment) => {
        const deployments = _.cloneDeep(this.state.deployments);
        deployments.forEach((deployment) => {
            deployment.request.QueueTime = queueTime;
            deployment.request.QueueTimeExpiry = queueTimeExpiry;
        });
        this.setState({ deployments, queueTime, queueTimeExpiry });
    };
    private onActionIdsToSkipChanged = (excludedActionIdsToSkip: string[]) => {
        const deployments = _.cloneDeep(this.state.deployments);
        deployments.forEach((deployment) => (deployment.request.SkipActions = excludedActionIdsToSkip));
        this.setState({ deployments, actionIdsToSkip: excludedActionIdsToSkip });
    };
    private onExcludeSpecificMachinesSelected = (machineInfo: DeploymentMachineInfo) => {
        this.setTargetMachineIds(machineInfo.deploymentType, machineInfo.id, machineInfo.machineIds, []);
    };
    private async loadAllEnvironments() {
        return repository.Environments.all();
    }
    private async loadAllDynamicEnvironments(): Promise<DynamicEnvironmentResource[]> {
        if (!this.props.dynamicEnvironmentsEnabled)
            return [];
        const dynamicEnvironmentsCollection = await repository.DynamicEnvironments.getMany();
        return dynamicEnvironmentsCollection.Items;
    }
    private async loadAllTenants(project: ProjectResource) {
        if (project && (project.TenantedDeploymentMode === TenantedDeploymentMode.Tenanted || project.TenantedDeploymentMode === TenantedDeploymentMode.TenantedOrUntenanted)) {
            return isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : [];
        }
        return [];
    }
    private onIncludeSpecificMachinesSelected = (machineInfo: DeploymentMachineInfo) => {
        this.setTargetMachineIds(machineInfo.deploymentType, machineInfo.id, [], machineInfo.machineIds);
    };
    private onAllTargetsSelected = (machineInfo: DeploymentMachineInfo) => {
        this.setTargetMachineIds(machineInfo.deploymentType, machineInfo.id, [], []);
    };
    private setTargetMachineIds = (deploymentType: DeploymentType, targetId: string, excludedMachineIds: string[], specificMachineIds: string[]) => {
        const deployments = _.cloneDeep(this.state.deployments);
        const deployment = deploymentType === DeploymentType.Tenant ? deployments.find((x) => x.tenantId === targetId) : deployments.find((x) => x.request.EnvironmentId === targetId);
        deployment!.request.ExcludedMachineIds = excludedMachineIds;
        deployment!.request.SpecificMachineIds = specificMachineIds;
        this.setState({ deployments });
    };
    private hasFailedAttempts(): boolean {
        return _.find(this.state.deployments, (deployment) => deployment.response && this.isError(deployment.response)) !== undefined;
    }
    private isError(response: IExecutionResource | OctopusError): response is OctopusError {
        return (response as OctopusError).ErrorMessage !== undefined;
    }
    static displayName = "DeploymentCreateInternal";
}
export function DeploymentCreate(props: DeploymentCreateProps) {
    const projectContext = useProjectContext();
    const trackAction = useProjectScopedAnalyticTrackedActionDispatch(projectContext.state.model.Id);
    const dynamicEnvironmentsEnabled = isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle");
    return <DeploymentCreateInternal {...props} projectContext={projectContext} trackAction={trackAction} dynamicEnvironmentsEnabled={dynamicEnvironmentsEnabled}/>;
}
