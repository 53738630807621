/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButton, RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import type { DockerRunActionEditProperties } from "@octopusdeploy/legacy-action-properties";
import type { FeedResource } from "@octopusdeploy/octopus-server-client";
import { ActionExecutionLocation, FeedType, GetPrimaryPackageReference, InitialisePrimaryPackageReference, SetPrimaryPackageReference } from "@octopusdeploy/octopus-server-client";
import { clone } from "lodash";
import * as React from "react";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import DeferredPackageSelector from "~/components/PackageSelector/DeferredPackageSelector";
import type { SummaryNode } from "~/components/form";
import { ExpandableFormSection, Summary } from "~/components/form";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import Note from "~/primitiveComponents/form/Note/Note";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import StringCheckbox, { BoundStringCheckbox } from "../../../primitiveComponents/form/Checkbox/StringCheckbox";
import CommonSummaryHelper from "../../../utils/CommonSummaryHelper/CommonSummaryHelper";
import ParseHelper from "../../../utils/ParseHelper/ParseHelper";
import DialogOpener from "../../Dialog/DialogOpener";
import KeyValueEditList from "../../EditList/KeyValueEditList";
import StringEditList from "../../EditList/StringEditList";
import { RemoveItemsList } from "../../RemoveItemsList/RemoveItemsList";
import ExpanderSectionHeading from "../../form/Sections/FormSectionHeading";
import { DisplayFeedName } from "../DisplayFeedName";
import Roles from "../Roles";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionWithFeeds } from "../commonActionHelpers";
import { getChangesToPackageReference } from "../getChangesToPackageReference";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
import type { VolumeBinding } from "./VolumeBindingDialog";
import VolumeBindingDialog from "./VolumeBindingDialog";
interface AzureCloudServiceActionSummaryState {
    feedName: string;
}
class BindingList extends RemoveItemsList<any> {
}
class DockerRunActionSummary extends BaseComponent<ActionSummaryProps, AzureCloudServiceActionSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }
    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        return pkg ? (<div>
                {"Deploy and Run a Docker Container"} <strong> {pkg.PackageId} </strong> {"from"} <DisplayFeedName pkg={pkg}/>
                {this.props.targetRolesAsCSV && (<span>
                        {" "}
                        on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV}/>{" "}
                    </span>)}
            </div>) : (<Callout type={CalloutType.Warning} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>);
    }
    static displayName = "DockerRunActionSummary";
}
interface DockerRunEditState {
    editBinding: VolumeBinding;
    editBindingIndex: number;
    volumeBindings: VolumeBinding[];
}
const NetworkTypes = {
    [""]: {
        name: "Default",
        description: "Provide no explicit type and use host default.",
    },
    none: {
        name: "None",
        description: "No networking.",
    },
    bridge: {
        name: "Bridge (Linux Default)",
        description: "Connect the container to the bridge via veth interfaces.",
    },
    host: {
        name: "Host",
        description: "Use the host's network stack inside the container.",
    },
    container: {
        name: "Container",
        description: "Use the network stack of another container, specified via its name or id.",
    },
    network: {
        name: "Custom network",
        description: "Connects the container to a user created network.",
    },
};
const RestartPolicies = {
    no: {
        name: "No (default)",
        description: "Do not automatically restart the container when it exits.",
    },
    ["on-failure"]: {
        name: "On failure",
        description: "Restart only if the container exits with a non-zero exit status. " + "Optionally, limit the number of restart retries the Docker daemon attempts.",
    },
    always: {
        name: "Always",
        description: "Always restart the container regardless of the exit status. " +
            "When you specify always, the Docker daemon will try to restart the container indefinitely. " +
            "The container will also always start on daemon startup, regardless of the current state of the container.",
    },
    ["unless-stopped"]: {
        name: "Unless stopped",
        description: "Always restart the container regardless of the exit status, but do not start it on daemon startup if the container has been put to a stopped state before.",
    },
};
type DockerRunActionProps = ActionEditProps<DockerRunActionEditProperties>;
type DockerRunActionInternalProps = DockerRunActionProps & ActionWithFeeds;
class DockerRunActionInternal extends BaseComponent<DockerRunActionInternalProps, DockerRunEditState> {
    constructor(props: DockerRunActionInternalProps) {
        super(props);
        this.state = {
            editBinding: null!,
            editBindingIndex: 0,
            volumeBindings: [],
        };
    }
    async componentDidMount() {
        this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.props.feeds), true);
        await this.props.doBusyTask(async () => {
            this.setState({
                volumeBindings: this.bindingsFromString(this.props.properties["Octopus.Action.Docker.VolumeBindings"]),
            });
        });
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (this.props.properties["Octopus.Action.Docker.VolumeBindings"] !== nextProps.properties["Octopus.Action.Docker.VolumeBindings"]) {
            this.setState({ volumeBindings: this.bindingsFromString(nextProps.properties["Octopus.Action.Docker.VolumeBindings"]) });
        }
    }
    addBinding = () => {
        const binding = {
            host: "",
            container: "",
            readOnly: "False",
            noCopy: "False",
        };
        this.setState({
            editBinding: binding,
            editBindingIndex: null!,
        });
    };
    bindingsFromString(rawProperty: string): VolumeBinding[] {
        if (!rawProperty) {
            return [];
        }
        const bindings = JSON.parse(rawProperty);
        return Object.keys(bindings).map((t) => ({
            container: t,
            host: bindings[t].host,
            readOnly: bindings[t].readOnly,
            noCopy: bindings[t].noCopy,
        }));
    }
    removeBinding = (b: any) => {
        const bindings = [...this.state.volumeBindings];
        bindings.splice(this.state.volumeBindings.indexOf(b), 1);
        this.props.setProperties({ ["Octopus.Action.Docker.VolumeBindings"]: this.bindingsToString(bindings) });
    };
    saveBinding = (binding: VolumeBinding) => {
        const bindings = [...this.state.volumeBindings];
        if (this.state.editBindingIndex === null) {
            bindings.push(binding);
        }
        else {
            bindings[this.state.editBindingIndex] = binding;
        }
        this.props.setProperties({ ["Octopus.Action.Docker.VolumeBindings"]: this.bindingsToString(bindings) });
        this.resetSelectedBinding();
        return true;
    };
    resetSelectedBinding = () => {
        this.setState({
            editBinding: null!,
            editBindingIndex: null!,
        });
    };
    bindingsToString(bindings: VolumeBinding[]): string {
        const bindingObject = bindings.reduce((idx: any, b) => {
            idx[b.container] = {
                host: b.host,
                readOnly: b.readOnly,
                noCopy: b.noCopy,
            };
            return idx;
        }, {});
        return JSON.stringify(bindingObject);
    }
    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }
        const pkg = GetPrimaryPackageReference(this.props.packages);
        const editBindingDialog = (<DialogOpener open={!!this.state.editBinding} onClose={this.resetSelectedBinding}>
                <VolumeBindingDialog existingContainerMappings={this.state.volumeBindings.filter((p) => !this.state.editBinding || p.container !== this.state.editBinding.container).map((vm) => vm.container)} binding={this.state.editBinding} project={undefined!} projectId={this.props.projectId!} localNames={this.props.localNames!} onAdd={this.saveBinding}/>
            </DialogOpener>);
        return (<div>
                {editBindingDialog}
                <ExpanderSectionHeading title="Docker Image details"/>
                <ExpandableFormSection errorKey="Octopus.Action.Package.PackageId|Octopus.Action.Package.FeedId" isExpandedByDefault={this.props.expandedByDefault} title="Docker Image" summary={CommonSummaryHelper.deferredPackageSummary(pkg, this.props.feeds)} help={<span>
                            This step is used to run a docker container. The package that you wish to deploy should contain all the files needed to run your application.{" "}
                            <ExternalLink href="DocumentationPackaging">Learn more about what your packages should contain, and how to create them</ExternalLink>.
                        </span>}>
                    <DeferredPackageSelector packageId={pkg.PackageId} feedId={pkg.FeedId} onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))} onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))} packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")} feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")} feedType={[FeedType.Docker, FeedType.AwsElasticContainerRegistry, FeedType.AzureContainerRegistry, FeedType.GoogleContainerRegistry]} projectId={this.props.projectId} feeds={this.props.feeds} localNames={this.props.localNames} refreshFeeds={this.loadFeeds} parameters={this.props.parameters} packageSelectionMode={pkg.Properties["SelectionMode"]} packageSelectionModeError={this.props.getFieldError("SelectionMode")} onPackageSelectionModeChange={(value) => this.props.setPackages(SetPrimaryPackageReference(getChangesToPackageReference(value), this.props.packages))} packageParameterName={pkg.Properties["PackageParameterName"]} packageParameterError={this.props.getFieldError("PackageParameterName")} onPackageParameterChange={(packageParameter) => this.props.setPackages(SetPrimaryPackageReference({ Properties: { ...pkg.Properties, PackageParameterName: packageParameter } }, this.props.packages))}/>
                </ExpandableFormSection>

                <ExpanderSectionHeading title="Networking Options"/>
                <ExpandableFormSection errorKey="Octopus.Action.Docker.NetworkType|Octopus.Action.Docker.NetworkContainer|Octopus.Action.Docker.NetworkName" isExpandedByDefault={this.props.expandedByDefault} title="Network Type" summary={this.summaryNetworkType()} help="Connect a container to a network.">
                    <RadioButtonGroup value={this.props.properties["Octopus.Action.Docker.NetworkType"] ?? ""} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Docker.NetworkType"]: val })}>
                        {Object.keys(NetworkTypes)
                .map((type) => [<RadioButton value={type} label={(NetworkTypes as any)[type].name} key={`rdo-${type}`}/>, <Note key={`note-${type}`}>{(NetworkTypes as any)[type].description}</Note>])
                .reduce((arr, dom) => arr.concat(dom), [])}
                    </RadioButtonGroup>

                    {this.props.properties["Octopus.Action.Docker.NetworkType"] === "container" && (<VariableLookupText localNames={this.props.localNames} label="Container name or ID" onChange={(val) => this.props.setProperties({ ["Octopus.Action.Docker.NetworkContainer"]: val })} value={this.props.properties["Octopus.Action.Docker.NetworkContainer"]}/>)}

                    {this.props.properties["Octopus.Action.Docker.NetworkType"] === "network" && (<VariableLookupText localNames={this.props.localNames} label="Network name or ID" onChange={(val) => this.props.setProperties({ ["Octopus.Action.Docker.NetworkName"]: val })} value={this.props.properties["Octopus.Action.Docker.NetworkName"]}/>)}
                </ExpandableFormSection>

                {this.props.properties["Octopus.Action.Docker.NetworkType"] === "network" && (<ExpandableFormSection errorKey="Octopus.Action.Docker.NetworkAlias" isExpandedByDefault={this.props.expandedByDefault} title="Network Alias" summary={this.propertySummary("Octopus.Action.Docker.NetworkAlias", "No network alias specified")} help="Add network-scoped alias for the container.">
                        <VariableLookupText localNames={this.props.localNames} label="Network alias" onChange={(val) => this.props.setProperties({ ["Octopus.Action.Docker.NetworkAlias"]: val })} value={this.props.properties["Octopus.Action.Docker.NetworkAlias"]}/>
                    </ExpandableFormSection>)}

                <ExpandableFormSection errorKey="Octopus.Action.Docker.PortMapping|Octopus.Action.Docker.PortAutoMap" isExpandedByDefault={this.props.expandedByDefault} title="Port Mapping" summary={this.summaryPortMappings()} help={<div>
                            <ExternalLink href="https://docs.docker.com/engine/reference/run/#/expose-incoming-ports">Publish</ExternalLink> a container's port or a range of ports to the host.
                        </div>}>
                    <KeyValueEditList items={this.props.properties["Octopus.Action.Docker.PortMapping"]} separator=":" name="Port Mapping" onChange={(val) => this.props.setProperties({ ["Octopus.Action.Docker.PortMapping"]: val })} valueLabel="Host port" keyLabel="Container port" projectId={this.props.projectId} gitRef={this.props.gitRef} reverseLayout={true}/>
                    <StringCheckbox value={this.props.properties["Octopus.Action.Docker.PortAutoMap"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Docker.PortAutoMap"]: x })} label="Automatically map to ephemeral port" note={<span>
                                Allows mapping exposed network port in the container to ports on the host. See{" "}
                                <ExternalLink href="https://docs.docker.com/engine/userguide/networking/default_network/dockerlinks/#/connect-using-network-port-mapping">Docker docs</ExternalLink> for more information about network port mapping.
                            </span>}/>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.Docker.AddedHost" isExpandedByDefault={this.props.expandedByDefault} title="Host Entry" summary={this.summaryAddedHost()} help="Adds a line to /etc/hosts.">
                    <KeyValueEditList items={this.props.properties["Octopus.Action.Docker.AddedHost"]} name="Host Entry" separator=":" onChange={(val) => this.props.setProperties({ ["Octopus.Action.Docker.AddedHost"]: val })} valueLabel="Host name" projectId={this.props.projectId} gitRef={this.props.gitRef} keyLabel="IP" reverseLayout/>
                </ExpandableFormSection>
                <ExpanderSectionHeading title="Volumes"/>
                <ExpandableFormSection errorKey="Octopus.Action.Docker.VolumeBindings" isExpandedByDefault={this.props.expandedByDefault} title="Bind Mounts" summary={this.summaryBindMounts()} help={<span>
                            A <ExternalLink href="https://docs.docker.com/engine/tutorials/dockervolumes/">data volume</ExternalLink> is a specially-designated directory within one or more containers that bypasses the{" "}
                            <ExternalLink href="https://docs.docker.com/engine/reference/glossary/#union-file-system">Union File System</ExternalLink>.
                        </span>}>
                    <BindingList listActions={[<ActionButton label="Add volume binding" onClick={() => this.addBinding()}/>]} data={this.state.volumeBindings} onRow={(binding) => {
                return (<div key={binding.container}>
                                    {binding.host ? (<span>
                                            <b>Host: </b>
                                            {binding.host} : <b>Container: </b>
                                            {binding.container}
                                        </span>) : (binding.container)}
                                    {this.isTrue(binding.readOnly, "readonly") && <b> readonly</b>}
                                    {this.isTrue(binding.noCopy, "nocopy") && <b> nocopy</b>}
                                </div>);
            }} onRowTouch={(binding) => this.editBinding(binding)} onRemoveRow={(binding) => this.removeBinding(binding)}/>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.Docker.VolumeDriver" isExpandedByDefault={this.props.expandedByDefault} title="Volume Driver" summary={this.propertySummary("Octopus.Action.Docker.VolumeDriver", "No volume driver specified")} help="Optional volume driver for the container.">
                    <VariableLookupText localNames={this.props.localNames} label="Volume driver" value={this.props.properties["Octopus.Action.Docker.VolumeDriver"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Docker.VolumeDriver"]: val })}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.Docker.VolumesFrom" isExpandedByDefault={this.props.expandedByDefault} title="Volumes From" summary={this.summaryVolumesFromContainers()} help="Mount all volumes from the given container(s).">
                    <StringEditList label="Container Volumes" items={this.props.properties["Octopus.Action.Docker.VolumesFrom"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Docker.VolumesFrom"]: val })}/>
                </ExpandableFormSection>

                <ExpanderSectionHeading title="Variables"/>
                <ExpandableFormSection errorKey="Octopus.Action.Docker.EnvVariable" isExpandedByDefault={this.props.expandedByDefault} title="Explicit Variable Mapping" summary={this.summaryVariables()} help={<span>
                            Passes through variables into the container accessible as environment variables. See <ExternalLink href="https://docs.docker.com/engine/reference/run/#/env-environment-variables">Docker docs</ExternalLink> for more
                            information about environment variables.
                        </span>}>
                    <KeyValueEditList items={this.props.properties["Octopus.Action.Docker.EnvVariable"]} name="Variable Mapping" separator="=" onChange={(val) => this.props.setProperties({ ["Octopus.Action.Docker.EnvVariable"]: val })} valueLabel="Mapping" projectId={this.props.projectId} gitRef={this.props.gitRef} keyLabel="Variable name"/>
                </ExpandableFormSection>

                <ExpanderSectionHeading title="Additional Arguments"/>
                <ExpandableFormSection errorKey="Octopus.Action.Docker.RestartPolicy|Octopus.Action.Docker.RestartPolicyMax" isExpandedByDefault={this.props.expandedByDefault} title="Restart Policy" summary={this.summaryRestartPolicy()} help={<span>
                            Restart policy to apply when a container exits. See <ExternalLink href="'https://docs.docker.com/engine/reference/run/#restart-policies-restart">Docker docs</ExternalLink> for more information.
                        </span>}>
                    <RadioButtonGroup value={this.props.properties["Octopus.Action.Docker.RestartPolicy"] || "no"} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Docker.RestartPolicy"]: val })}>
                        {Object.keys(RestartPolicies)
                .map((type) => [<RadioButton value={type} label={(RestartPolicies as any)[type].name} key={`rdo-${type}`}/>, <Note key={`note-${type}`}>{(RestartPolicies as any)[type].description}</Note>])
                .reduce((arr, dom) => arr.concat(dom), [])}
                    </RadioButtonGroup>
                    {this.props.properties["Octopus.Action.Docker.RestartPolicy"] === "on-failure" && (<DebounceText label="Maximum retry count" placeholder="Unlimited retry" value={this.props.properties["Octopus.Action.Docker.RestartPolicyMax"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Docker.RestartPolicyMax"]: val })}/>)}
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.Docker.DontRun" isExpandedByDefault={this.props.expandedByDefault} title="Don't Auto-run" summary={this.summaryAutoRun()} help={<span>This creates the writable layer on top of the image and prepares it for running without actually starting it . This may be useful if there is other configuration you would like to perform before startup.</span>}>
                    <BoundStringCheckbox variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={"False"} label={<span>
                                Perform <code>create</code> command instead of <code>run</code>
                            </span>} value={this.props.properties["Octopus.Action.Docker.DontRun"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Docker.DontRun"]: val })}/>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.Docker.Command" isExpandedByDefault={this.props.expandedByDefault} title="Command" summary={this.propertySummary("Octopus.Action.Docker.Command", "No custom command specified")} help={<span>
                            <ExternalLink href="https://docs.docker.com/engine/reference/run/#cmd-default-command-or-options">Override default</ExternalLink> <code>CMD</code> instruction provided by the image.
                        </span>}>
                    <VariableLookupText localNames={this.props.localNames} label="Command" value={this.props.properties["Octopus.Action.Docker.Command"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Docker.Command"]: val })}/>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.Docker.Args" isExpandedByDefault={this.props.expandedByDefault} title="Additional Arguments" summary={this.propertySummary("Octopus.Action.Docker.Args", "No additional arguments specified.")} help={<span>
                            Provide any other arguments that will be passed to the <code>docker run</code> command.
                        </span>}>
                    <VariableLookupText localNames={this.props.localNames} label="Additional arguments" multiline={true} value={this.props.properties["Octopus.Action.Docker.Args"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Docker.Args"]: val })}/>
                    <Note>
                        A full description of the additional arguments which can be supplied can be found on the <ExternalLink href="DockerRun">Docker reference page</ExternalLink>.
                    </Note>
                </ExpandableFormSection>
            </div>);
    }
    editBinding = (binding: VolumeBinding) => {
        this.setState({
            editBinding: clone(binding),
            editBindingIndex: this.state.volumeBindings.indexOf(binding),
        });
    };
    private isTrue(noCopy: boolean | string, trueString: string) {
        return !!noCopy && (noCopy === true || noCopy.toLowerCase() === "true" || noCopy.toLowerCase() === trueString);
    }
    private propertySummary(property: string, defaultValue: any): SummaryNode {
        const subnet = (this.props.properties as any)[property];
        return subnet ? Summary.summary(subnet) : Summary.placeholder(defaultValue);
    }
    private summaryNetworkType(): SummaryNode {
        const type = this.props.properties["Octopus.Action.Docker.NetworkType"] || "";
        let text = (NetworkTypes as any)[type].description;
        if (type === "") {
            return Summary.default(text);
        }
        if (type === "container") {
            text += " (" + this.props.properties["Octopus.Action.Docker.NetworkContainer"] + ")";
        }
        else if (type === "network") {
            text += " (" + this.props.properties["Octopus.Action.Docker.NetworkName"] + ")";
        }
        return Summary.summary(text);
    }
    private summaryPortMappings() {
        const portMappings = JSON.parse(this.props.properties["Octopus.Action.Docker.PortMapping"] || "{}");
        if (Object.keys(portMappings).length === 0) {
            return Summary.placeholder("No port mappings provided");
        }
        else {
            const text = Object.keys(portMappings)
                .map((m) => (portMappings[m] ? portMappings[m] + " : " : "") + m)
                .join(", ");
            return Summary.summary(text);
        }
    }
    private summaryAddedHost() {
        const hosts = JSON.parse(this.props.properties["Octopus.Action.Docker.AddedHost"] || "{}");
        if (Object.keys(hosts).length === 0) {
            return Summary.placeholder("No host entries added");
        }
        else {
            const text = Object.keys(hosts)
                .map((m) => m + (hosts[m] ? " : " + hosts[m] : ""))
                .join(", ");
            return Summary.summary(text);
        }
    }
    private summaryVolumesFromContainers() {
        const raw = ParseHelper.parseCSV(this.props.properties["Octopus.Action.Docker.VolumesFrom"] || "");
        if (raw.length === 0) {
            return Summary.placeholder("No container volumes specified");
        }
        const pluralized = raw.length === 1 ? "" : "s";
        return Summary.summary(<span>
                Volume{pluralized} mounted from container{pluralized} <b>{raw.join(", ")}</b>
            </span>);
    }
    private summaryBindMounts() {
        if (this.state.volumeBindings.length === 0) {
            return Summary.placeholder("No volume bindings specified");
        }
        const containerBindings = this.state.volumeBindings.map((b) => b.container);
        const pluralized = containerBindings.length === 1 ? "" : "s";
        return Summary.summary(<span>
                Volume binding{pluralized} mapped to mount{pluralized} <b>{containerBindings.join(", ")}</b>
            </span>);
    }
    private summaryAutoRun() {
        const val = this.props.properties["Octopus.Action.Docker.DontRun"] || "false";
        if (val.toLowerCase() === "true") {
            return Summary.summary("Container will be created in stopped mode");
        }
        return Summary.placeholder("Container will run when created");
    }
    private summaryRestartPolicy() {
        let policy = this.props.properties["Octopus.Action.Docker.RestartPolicy"];
        const restartPolicyMax = this.props.properties["Octopus.Action.Docker.RestartPolicyMax"];
        if (!policy) {
            policy = "no";
        }
        let text = (RestartPolicies as any)[policy].description;
        if (policy === "no") {
            return Summary.default(text);
        }
        if (policy === "on-failure") {
            text += restartPolicyMax ? ` (max ${restartPolicyMax} restarts)` : "(unlimited restarts)";
        }
        return Summary.summary(text);
    }
    private summaryVariables() {
        const variables = JSON.parse(this.props.properties["Octopus.Action.Docker.EnvVariable"] || "{}");
        if (Object.keys(variables).length === 0) {
            return Summary.placeholder("No environment variables specified");
        }
        else {
            const text = Object.keys(variables)
                .map((m) => m + " = " + variables[m])
                .join(", ");
            return Summary.summary(text);
        }
    }
    private loadFeeds = async (callback?: (feeds: FeedResource[]) => void) => {
        await this.props.refreshFeeds();
    };
    static displayName = "DockerRunActionInternal";
}
export function DockerRunAction(props: DockerRunActionProps) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    return <DockerRunActionInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds}/>;
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.DockerRun",
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
    summary: (properties, targetRolesAsCSV, packages) => <DockerRunActionSummary properties={properties} packages={packages} targetRolesAsCSV={targetRolesAsCSV}/>,
    canHaveChildren: (step) => true,
    canBeChild: true,
    editSections: { default: DockerRunAction },
});
