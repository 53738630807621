import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import classnames from "classnames";
import type { ReactNode } from "react";
import * as React from "react";
import type { DataBaseComponentState, DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import styles from "./style.module.less";
interface ConfirmUpgradeDialogLayoutProps {
    open: boolean;
    title: string;
    confirmButtonLabel?: string;
    confirmButtonBusyLabel?: string;
    confirmButtonDisabled?: boolean;
    renderContent(doBusyTask: DoBusyTask): ReactNode;
    onConfirmUpgradeClick(): Promise<boolean> | void;
    onClose(): void;
}
class ConfirmUpgradeDialogLayout extends DataBaseComponent<ConfirmUpgradeDialogLayoutProps, DataBaseComponentState> {
    constructor(props: ConfirmUpgradeDialogLayoutProps) {
        super(props);
        this.state = {};
    }
    UNSAFE_componentWillReceiveProps(nextProps: ConfirmUpgradeDialogLayoutProps) {
        if (this.props.open !== nextProps.open) {
            this.clearErrors();
        }
    }
    onConfirmUpgradeClick() {
        return this.doBusyTask(async () => {
            const result = await this.props.onConfirmUpgradeClick();
            if (result) {
                this.props.onClose();
            }
        });
    }
    render() {
        const del = (<ActionButton key="Continue" label={this.props.confirmButtonLabel || "ConfirmUpgrade"} type={ActionButtonType.Save} busyLabel={this.props.confirmButtonBusyLabel || "Confirming..."} disabled={this.props.confirmButtonDisabled || this.state.busy} onClick={() => this.onConfirmUpgradeClick()}/>);
        const cancel = <ActionButton key="Cancel" label="Cancel" disabled={this.state.busy} onClick={() => this.props.onClose()}/>;
        return (<DialogLayout actions={[cancel, del]} title={this.props.title} titleIcon={<em className={classnames("fa-solid fa-triangle-exclamation", styles.warningIcon)}/>} headerClassName={styles.confirmationDialogHeader} busy={this.state.busy} closeDialog={this.props.onClose} errors={this.errors}>
                {this.props.open && this.props.renderContent(this.doBusyTask)}
            </DialogLayout>);
    }
    static displayName = "ConfirmUpgradeDialogLayout";
}
type ConfirmUpgradeDialogProps = ConfirmUpgradeDialogLayoutProps;
//We need to ensure our dialog layout lives below the `Dialog` in the component hierarchy
//to ensure busy task errors bubble up to the correct error context as part of `DoBusyTask` usages.
export default function ConfirmUpgradeDialog(props: ConfirmUpgradeDialogProps) {
    return (<Dialog open={props.open || false}>
            <ConfirmUpgradeDialogLayout {...props}/>
        </Dialog>);
}
