import { ActionExecutionLocation, GetPrimaryPackageReference } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { TentaclePackageActionEdit } from "~/components/Actions/tentaclePackage/tentaclePackageAction";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import { DisplayFeedName } from "../DisplayFeedName";
import Roles from "../Roles";
import type { ActionSummaryProps } from "../actionSummaryProps";
import pluginRegistry from "../pluginRegistry";
interface IISActionSummaryState {
    feedName: string;
}
class IISActionSummary extends BaseComponent<ActionSummaryProps, IISActionSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }
    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        return pkg ? (<div>
                {"Deploy to IIS using package"} <strong>{pkg.PackageId}</strong> {"from"} <DisplayFeedName pkg={pkg}/>
                {this.props.targetRolesAsCSV && (<span>
                        {"to deployment targets in"} <Roles rolesAsCSV={this.props.targetRolesAsCSV}/>
                    </span>)}
            </div>) : (<Callout type={CalloutType.Warning} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>);
    }
    static displayName = "IISActionSummary";
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.IIS",
    summary: (properties, targetRolesAsCSV, packages) => <IISActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages}/>,
    editSections: { default: TentaclePackageActionEdit },
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
    features: {
        permanent: ["Octopus.Features.IISWebSite"],
        optional: [
            "Octopus.Features.ConfigurationTransforms",
            "Octopus.Features.ConfigurationVariables",
            "Octopus.Features.CustomDirectory",
            "Octopus.Features.CustomScripts",
            "Octopus.Features.JsonConfigurationVariables",
            "Octopus.Features.SubstituteInFiles",
            "Octopus.Features.SelectPowerShellEditionForWindows",
        ],
        initial: ["Octopus.Features.ConfigurationTransforms", "Octopus.Features.ConfigurationVariables"],
    },
});
