import { HTML5toTouch } from "rdndmb-html5-to-touch";
import * as React from "react";
import { DndProvider } from "react-dnd-multi-backend";
import SortableItem from "./SortableItem";
interface SortableItemModel {
    Id: string;
    Name: string;
}
interface SortableListProps<TItemType> {
    items: TItemType[];
    onOrderChanged(sortedItems: TItemType[]): void;
}
class SortableList<TItemType extends SortableItemModel> extends React.Component<SortableListProps<TItemType>> {
    render() {
        return (<DndProvider options={HTML5toTouch}>
                <div>
                    {this.props.items.map((item, index) => (<SortableItem key={item.Id} index={index} id={item.Id} name={item.Name} onItemMoved={this.itemMoved}/>))}
                </div>
            </DndProvider>);
    }
    private itemMoved = (dragIndex: number, hoverIndex: number) => {
        const orderedItems = this.props.items.slice();
        const dragItem = orderedItems[dragIndex];
        orderedItems.splice(dragIndex, 1);
        orderedItems.splice(hoverIndex, 0, dragItem);
        this.props.onOrderChanged(orderedItems);
    };
    static displayName = "SortableList";
}
export default SortableList;
export { SortableItemModel };
