import { useAnalyticSession } from "@octopusdeploy/portal-analytics";
import type { PageDefinition } from "@octopusdeploy/portal-routes";
import { usePage } from "@octopusdeploy/portal-routes";
import React from "react";
const HelpInteractionEvent = "Help Panel";
export const helpInteractionType = {
    open: "open",
    close: "close",
} as const;
type HelpInteractionType = (typeof helpInteractionType)[keyof typeof helpInteractionType];
export function useDispatchHelpInteraction() {
    const session = useAnalyticSession();
    const page = usePage();
    return React.useCallback((interaction: HelpInteractionType, interactedElement: string) => session.track(HelpInteractionEvent, mapOptionsToAnalyticFields(interaction, interactedElement, page)), [session, page]);
}
interface SpaceSwitcherInteractionAnalyticFields {
    "Page Area": string;
    "Page Name": string;
    Interaction: HelpInteractionType;
    InteractedElement: string;
}
function mapOptionsToAnalyticFields(interaction: HelpInteractionType, interactedElement: string, page: PageDefinition): SpaceSwitcherInteractionAnalyticFields {
    return {
        "Page Area": page.Area,
        "Page Name": page.Name,
        Interaction: interaction,
        InteractedElement: interactedElement,
    };
}
