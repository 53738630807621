import type { ChannelResource, EnvironmentResource, GitRefResource, LifecycleResource, ProjectResource, ResourcesById, TagSetResource, WorkerPoolResource, WorkerPoolsSummaryResource } from "@octopusdeploy/octopus-server-client";
import { Permission, ProcessType, VariableSetContentType } from "@octopusdeploy/octopus-server-client";
import { keyBy } from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import type { RouteComponentProps } from "react-router";
import { useParams } from "react-router";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import * as tenantTagsets from "~/components/tenantTagsets";
import StringHelper from "~/utils/StringHelper";
import { useProjectContext } from "../../context";
import type { ScriptModule } from "../Process/Common/SideBar";
import { useOptionalRunbookContext } from "../Runbooks/RunbookContext";
import { ProcessContextFormPage } from "./Contexts/ProcessContextFormPage";
import { ProcessController } from "./Contexts/ProcessController";
import { createDefaultFilter, ProcessQueryStringController } from "./Contexts/ProcessQueryString/ProcessQueryStringContext";
import ProcessStepsLayout from "./ProcessStepsLayout";
import type { ProcessPageSupportedActions } from "./types";
interface GlobalConnectedProps {
    isBuiltInWorkerEnabled?: boolean;
}
interface ProcessState extends DataBaseComponentState {
    lookups: ProcessStepsLayoutLoaderLookupData;
}
interface ProcessProps extends GlobalConnectedProps {
    processId: string;
    processType: ProcessType;
}
type ProcessPageProps = ProcessProps;
type ProcessStepsLayoutLoaderProps = ProcessProps & {
    project: Readonly<ProjectResource>;
    stepSlug?: string;
    gitRef: GitRefResource | undefined;
};
export interface ProcessStepsLayoutLoaderLookupData {
    includedScriptModules: ScriptModule[];
    lifecyclePreview: LifecycleResource | null;
    environmentsById: ResourcesById<EnvironmentResource>;
    channelsById: ResourcesById<ChannelResource> | null;
    tagSets: TagSetResource[];
    workerPoolsById: ResourcesById<WorkerPoolResource>;
    machineRoles: string[];
    tagIndex: tenantTagsets.TagIndex;
    workerPoolsSummary: WorkerPoolsSummaryResource;
}
const loadData = async (project: ProjectResource, gitRef: GitRefResource | undefined, processType: ProcessType): Promise<ProcessStepsLayoutLoaderLookupData> => {
    const includedScriptModules = isAllowed({ permission: Permission.LibraryVariableSetView, environment: "*", tenant: "*" })
        ? repository.LibraryVariableSets.all({
            contentType: VariableSetContentType.ScriptModule,
        }).then((sm) => sm.filter((x) => project.IncludedLibraryVariableSetIds.includes(x.Id)))
        : Promise.resolve([]);
    const environments = repository.Environments.all();
    const lifecyclePreview = processType === ProcessType.Deployment && isAllowed({ permission: Permission.LifecycleView }) ? repository.Lifecycles.get(project.LifecycleId).then((x) => repository.Lifecycles.preview(x)) : Promise.resolve(null);
    const channelsById = processType === ProcessType.Deployment &&
        isAllowed({
            permission: Permission.ProcessView,
            project: project.Id,
        })
        ? repository.Projects.getChannels(project).then((c) => keyBy(c.Items, "Id"))
        : Promise.resolve(null);
    const tagSets = tenantTagsets.getAll();
    const workerPools = repository.WorkerPools.all();
    return {
        environmentsById: keyBy(await environments, "Id"),
        includedScriptModules: await includedScriptModules,
        lifecyclePreview: await lifecyclePreview,
        channelsById: await channelsById,
        tagSets: await tagSets,
        workerPoolsById: keyBy(await workerPools, "Id"),
        machineRoles: await repository.MachineRoles.all(),
        tagIndex: await tenantTagsets.getTagIndex(),
        workerPoolsSummary: await repository.WorkerPools.summary(),
    };
};
function useIsBuiltInWorkerEnabled() {
    return useSelector((state: GlobalState) => state.configurationArea.features.isBuiltInWorkerEnabled);
}
const PageLoader = ProcessContextFormPage<ProcessStepsLayoutLoaderLookupData>();
const ProcessStepsLayoutDataLoader: React.FC<ProcessPageProps> = (props) => {
    const { stepSlug } = useParams<{
        stepSlug?: string;
    }>();
    const { state } = useProjectContext();
    const { model: project, gitRef } = state;
    const isBuiltInWorkerEnabled = useIsBuiltInWorkerEnabled();
    const runbookContext = useOptionalRunbookContext();
    const runbookName = runbookContext?.state.runbook?.Name ?? StringHelper.ellipsis;
    return (<PageLoader processType={props.processType} title={props.processId === ProcessType.Deployment ? "Process" : runbookName} load={() => loadData(project, gitRef, props.processType)} renderWhenLoaded={(data) => <ProcessStepsLayoutLoader isBuiltInWorkerEnabled={isBuiltInWorkerEnabled} initialLookups={data} project={project} gitRef={gitRef} stepSlug={stepSlug} {...props}/>}/>);
};
ProcessStepsLayoutDataLoader.displayName = "ProcessStepsLayoutDataLoader"
interface InitialLookupData {
    initialLookups: ProcessStepsLayoutLoaderLookupData;
}
class ProcessStepsLayoutLoader extends DataBaseComponent<ProcessStepsLayoutLoaderProps & InitialLookupData, ProcessState> {
    constructor(props: ProcessStepsLayoutLoaderProps & InitialLookupData & RouteComponentProps) {
        super(props);
        this.state = {
            lookups: props.initialLookups,
        };
    }
    //TODO: @Cleanup: This is an artifact of the data-loading pattern we have here. Ideally this could potentially all be moved into context.
    supportedActions(): ProcessPageSupportedActions {
        const refreshLookupData = async () => {
            await this.doBusyTask(async () => this.setState({ lookups: await loadData(this.props.project, this.props.gitRef, this.props.processType) }));
        };
        return {
            refreshLookupData,
        };
    }
    render() {
        return (<ProcessController layoutActions={this.supportedActions()} processType={this.props.processType} id={this.props.processId} doBusyTask={this.doBusyTask} project={this.props.project}>
                {() => {
                return (<ProcessQueryStringController initialQueryFilter={createDefaultFilter()}>
                            {() => {
                        return (<ProcessStepsLayout lookups={this.state.lookups} errors={this.errors} busy={this.state.busy} doBusyTask={this.doBusyTask} isBuiltInWorkerEnabled={this.props.isBuiltInWorkerEnabled ?? false} stepSlug={this.props.stepSlug}/>);
                    }}
                        </ProcessQueryStringController>);
            }}
            </ProcessController>);
    }
    static displayName = "ProcessStepsLayoutLoader";
}
// We export our data-loader wrapper, as that wraps out layout and supplies the necessary data to the layout.
export default ProcessStepsLayoutDataLoader;
